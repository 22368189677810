import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IdentitiesContext } from "../../context/IdentitiesProvider";
import usePromise from "../../hooks/usePromise";
import ProfileService from "../../services/ProfileService";
import ErrorsAlert from "../Common/ErrorsAlert";
import IdentityItem from "./IdentityItem";

const IdentitiesList = () => {
  const intl = useIntl();
  const { handleRegisterButtonClick, currentIdentity } = useContext(
    IdentitiesContext
  );
  const [data, errors, loading, setPromise, reset] = usePromise();
  const [identities, setIdentities] = useState([]);

  useEffect(() => {
    setPromise(
      ProfileService.setErrorMessages({
        "Invalid $sortBy value": intl.formatMessage({
          id: "errors.unexpected-error",
          defaultMessage: "An unexpected error occurred.",
        }),
        "Invalid $order value": intl.formatMessage({
          id: "errors.unexpected-error",
          defaultMessage: "An unexpected error occurred.",
        }),
      }).getIdentities()
    );
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    data && setIdentities(data);
    return () => {
      setIdentities([]);
    };
  }, [data]);

  useEffect(() => {
    if (currentIdentity && currentIdentity.identityValue) {
      let alreadyExists = identities.find(
        (identity) => identity.identityValue === currentIdentity.identityValue
      );
      !alreadyExists && setIdentities([...identities, currentIdentity]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIdentity]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" display="block">
          <FormattedMessage
            id="identities.title"
            defaultMessage="Identities"
            description="Profile menu link"
          />
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegisterButtonClick}
            data-testid="add-identity-button"
          >
            <FormattedMessage
              id="button.add-identity"
              defaultMessage="Add Identity"
              description="Add Identity button text"
            />
          </Button>
        </Box>
      </Box>
      <Box my={2}>
        {loading ? (
          <Box display="grid" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {errors && <ErrorsAlert errors={errors} />}
            {identities.length &&
              identities.map((identity) => (
                <IdentityItem
                  identity={identity}
                  key={identity.identityValue}
                />
              ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default IdentitiesList;
