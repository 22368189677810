import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const BadgesListSearchFilter = (props) => {
  const intl = useIntl();
  const [rawSearch, setRawSearch] = useState("");

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      props.handleSearchChange(rawSearch);
    }, 300);
    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawSearch]);

  return (
    <TextField
      placeholder={intl.formatMessage({
        id: "badges-filters.search-placeholder",
        defaultMessage: "Search by title, description or group",
        description: "Search filter placeholder",
      })}
      variant="outlined"
      onChange={(evt) => setRawSearch(evt.target.value)}
      value={rawSearch}
      fullWidth
      size="small"
      label={intl.formatMessage({
        id: "badges-filters.search-label",
        defaultMessage: "Search",
        description: "Search filter label",
      })}
    />
  );
};

export default BadgesListSearchFilter;
