import { Hidden, Icon } from "@material-ui/core";
import React from "react";

const IdentityItemIcon = (props) => {
  if (!props.type) {
    return <></>;
  }

  const { type, ...rest } = props;

  const icon = (type) => {
    switch (type) {
      case "email":
        return <Icon {...rest}>alternate_email</Icon>;
      case "phone":
        return <Icon {...rest}>phone</Icon>;
      case "domain":
        return <Icon {...rest}>domain</Icon>;
      default:
        return <></>;
    }
  };

  return <Hidden mdDown>{icon(type)}</Hidden>;
};

export default IdentityItemIcon;
