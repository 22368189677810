import { Badge, Icon, IconButton, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserProvider";
import UserAvatar from "../Common/UserAvatar";
import ProfileFormAvatarDialog from "./ProfileFormAvatarDialog";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: theme.spacing(6),
    textTransform: "uppercase",
  },
  editButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  input: {
    display: "none",
  },
}));

const ProfileFormAvatar = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [file, setFile] = useState(false);
  const { user } = useContext(UserContext);

  const handleFileOnChange = (evt) => {
    setDialogOpen(true);
    setFile(evt.target.files[0]);
  };

  return (
    <>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={handleFileOnChange}
        data-testid="contained-button-file"
      />

      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          <label htmlFor="contained-button-file">
            <IconButton
              className={classes.editButton}
              size="small"
              aria-label="upload picture"
              component="span"
            >
              <Icon fontSize="small">edit</Icon>
            </IconButton>
          </label>
        }
      >
        <UserAvatar
          image={user.avatarImage}
          alt={user.email}
          className={classes.avatar}
        />
      </Badge>
      <ProfileFormAvatarDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        file={file}
      />
    </>
  );
};

export default ProfileFormAvatar;
