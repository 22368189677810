const privacyPolicy = {
  es: (
    <>
      <p>
        Su privacidad es importante para nosotros. Es la política de Badge Sash
        respetar su privacidad y cumplir con cualquier regulación o ley
        aplicable con respecto a cualquier información personal que podamos
        recolectar acerca de usted, en nuestro website,{" "}
        <a href="https://badge-sash.com">https://badge-sash.com</a>, y otros
        sitios que nos pertenecen y operamos.
      </p>
      <p>
        Esta política es efectiva desde el 5 de Abril de 2022 y fue actualizada
        por última vez el 5 de Abril 2022.
      </p>
      <h3>Información que recolectamos</h3>
      <p>
        La información que recolectamos incluye tanto información que usted nos
        provee de forma consciente y activa cuando utiliza o participa de
        cualquiera de nuestros servicios o promociones, y cualquier información
        enviada automáticamente por su dispositivo al acceder a nuestros
        productos y servicios.
      </p>
      <h4>Datos registrados</h4>
      <p>
        Cuando usted visita nuestro website, nuestros servidores pueden
        automáticamente registrar los datos provistos por su navegador de forma
        estándar. Estos pueden incluir la dirección IP (Internet Protocol) de su
        dispositivo, su tipo de navegador y versión, las páginas que usted
        visita, la fecha y hora de su visita, el tiempo que permanece en cada
        página, otros detalles acerca de su visita, y detalles técnicos que
        ocurren en conjunción con errores que puede encontrarse.
      </p>
      <p>
        Por favor sea consciente que mientras esta información puede no
        identificarle personalmente por si misma, puede ser posible combinarla
        con otra información para identificar su persona de forma individual.
      </p>
      <h4>Información Personal</h4>
      <p>
        Le podemos solicitar información personal la cual puede incluir uno o
        más de los siguientes puntos:
      </p>
      <ul>
        <li>Nombre</li>
        <li>Email</li>
        <li>Número de teléfono/celular</li>
        <li>Insignias digitales</li>
      </ul>
      <h4>Razones legítimas para procesar su información personal</h4>
      <p>
        Solo recolectamos y usamos su información personal cuando tenemos una
        razón legítima para hacerlo. En tal instancia, solo recolectamos
        información personal que es razonablemente necesaria para proveerle
        nuestros servicios.
      </p>
      <h4>Recolección y uso de información</h4>
      <p>
        Podemos recolectar su información personal cuando realice algo de lo
        siguiente en nuestro website:
      </p>
      <ul>
        <li>
          Se registre para recibir actualizaciones de nosotros via email o redes
          sociales
        </li>
        <li>
          Use un dispositivo móvil o navegador para acceder a nuestro contenido
        </li>
        <li>
          Cuando nos contacte via email, red social, o cualquier tecnología
          similar
        </li>
        <li>Cuando nos mencione en una red social</li>
      </ul>
      <p>
        Podemos recolectar, mantener, usar, y revelar información para los
        siguiente propósitos, y la información personal no será procesada de
        ninguna manera que sea incompatible con estos propósitos:
      </p>
      <ul>
        <li>contactarlo y comunicarse con usted</li>
        <li>
          para permitirle acceder y utilizar nuestro website, aplicaciones
          asociadas, y plataformas de redes sociales
        </li>
        <li>para registro interno y propósitos administrativos</li>
      </ul>
      <p>
        Por favor sea consciente de que podemos combinar la información que
        recolectamos acerca de usted con información general o datos de
        investigación que recibamos de otras fuentes confiables.
      </p>
      <h4>Seguridad de su información personal</h4>
      <p>
        Cuando recolectemos y procesemos información personal, y mientras
        mantengamos esta información, la protegeremos con los medios
        comercialmente aceptados para prevenir su perdida o robo, como también
        su acceso no autorizado, divulgación, copia, uso, o modificación.
      </p>
      <p>
        Si bien haremos lo mejor posible para proteger la información personal
        que nos provea, le advertimos que ningún medio electrónico de
        transmisión o almacenamiento es 100% seguro, y nadie puede garantizar
        una seguridad de datos absoluta. Cumpliremos con la leyes aplicables en
        caso de cualquier filtración de datos.
      </p>
      <p>
        Usted es responsable de seleccionar una contraseña, y su fortaleza,
        asegurando la seguridad de su propia información dentro de los límites
        de nuestros servicios.
      </p>
      <h4>Cuanto tiempo guardamos su información personal</h4>
      <p>
        Guardamos su información personal tanto tiempo como lo necesitemos. Este
        período puede depender del para qué estemos utilizando su información,
        en concordancia con esta política de privacidad. Si su información
        personal ya no es requerida, la eliminaremos o la haremos anónima
        eliminando todos los detalles que le identifiquen.
      </p>
      <p>
        Sin embargo, si es necesario, podremos retener su información personal
        para nuestro cumplimiento con cualquier propósito legal, contable, u
        obligación de reporte o archivo de interés público, científico, o de
        propósito histórico o estadístico.
      </p>
      <h3>Privacidad infantil</h3>
      <p>
        No apuntamos ninguno de nuestros productos o servicios directamente a
        niños menores de 13 años, y no recolectamos de forma intencional
        información personal de niños menores de 13 años.
      </p>
      <h3>Divulgación de información personal a terceros</h3>
      <p>Podremos divulgar información personal a: </p>
      <ul>
        <li>una casa central, subsidiaria, o afiliada de nuestra compañía</li>
        <li>
          proveedores de servicios para el propósito de permitirles brindar su
          servicio, por ejemplo: proveedores de servicios informáticos,
          almacenamiento de datos, proveedores de servidores y alojamiento,
          anunciantes, o plataformas de analítica
        </li>
        <li>nuestros empleados, contratados, y/o entidades relacionadas</li>
        <li>nuestros agentes o socios comerciales existentes o potenciales</li>
        <li>
          patrocinadores o promotores de cualquier competición, sorteo, o
          promoción que llevemos a cabo
        </li>
        <li>
          cortes, tribunales, autoridades regulatorias, y agentes del orden,
          según sea requerido por ley, en conexión con cualquier procedimiento
          legal actual o futuro, o en orden a establecer, ejercer, o defender
          nuestros derechos legales
        </li>
        <li>
          terceros, incluyendo agentes o subcontratados, que nos asistan en
          proveerle información, productos, servicios o marketing directo
        </li>
      </ul>
      <h3>Transferencias internacionales de información personal</h3>
      <p>
        La información personal que recolectemos será almacenada y/o procesada
        donde nosotros o nuestros socios, afiliados, y proveedores mantengan sus
        instalaciones. Por favor sea consciente que las ubicaciones donde
        almacenamos, procesamos, o transferimos su información personal pueden
        no tener las mismas leyes de protección de datos que el país del que
        usted haya provisto la información inicialmente. Si transferimos su
        información personal a terceros en otros países: (i) haremos esas
        transferencias de acuerdo con los requisitos aplicables por ley; y (ii)
        protegeremos la información personal transferida de acuerdo con esta
        política de privacidad.
      </p>
      <h3>Sus derechos y control de su información personal</h3>
      <p>
        Usted conserva siempre el derecho a denegarnos información personal,
        entendiendo que su experiencia en nuestro website puede verse afectado.
        No lo discriminaremos por ejercer cualquiera de sus derechos sobre su
        información personal. Si nos provee con información personal usted
        entiende que la recolectaremos, almacenaremos, utilizaremos y
        divulgaremos de acuerdo con esta política de privacidad. Usted conserva
        el derecho a solicitar detalles de cualquier información personal que
        almacenemos acerca de usted.
      </p>
      <p>
        Si recibimos información personal acerca de usted de un tercero, la
        protegeremos según lo establecido en esta política de privacidad. Si
        usted es un tercero proveyendo información personal acerca de alguien
        más, usted representa y garantiza que tiene el consentimiento de esa
        otra persona a proveernos su información personal.
      </p>
      <p>
        Si usted nos ha habilitado previamente a utilizar su información
        personal para propósitos de marketing directo, puede cambiar de parecer
        en cualquier momento. Le daremos la capacidad de desuscribirse de
        nuestra base de datos de emails o desactivar nuestros comunicados. Sea
        consciente que necesitaremos solicitarle información específica que nos
        ayude a confirmar su identidad.
      </p>
      <p>
        Si usted cree que cualquier información que almacenamos acerca de usted
        es imprecisa, desactualizada, incompleta, irrelevante, o errónea, por
        favor contáctenos usando los detalles provistos en esta política de
        privacidad. Tomaremos los pasos necesarios para corregir cualquier
        información que encontremos imprecisa, incompleta, errónea, o
        desactualizada.
      </p>
      <p>
        Si usted cree que hemos violado una ley de protección de datos relevante
        y desea realizar una queja, por favor contáctenos usando los detalles
        provistos debajo y proveanos todos los detalles de la alegada violación.
        Investigaremos su queja de inmediato y le responderemos, por escrito,
        estableciendo el resultado de nuestra investigación y los pasos que
        tomaremos para tratar su queja. También tiene derecho a contactar un
        organismo regulador o autoridad de protección de datos en relación a su
        queja.
      </p>
      <h3>Usamos Cookies</h3>
      <p>
        Usamos &ldquo;cookies&rdquo; para recolectar información acerca de usted
        y su actividad en nuestro website. Una cookie es una pequeña pieza de
        información que nuestro website almacena en su computadora, y se accesa
        cada vez que usted visita el website, para que podamos entender cómo
        usted utiliza nuestro website. Esto nos ayuda a servirle contenido
        basado en las preferencias que haya especificado.
      </p>
      <h3>Límites de nuestra política</h3>
      <p>
        Nuestro website puede enlazar a sitios externos que no son operados por
        nosotros. Por favor sea consciente que no tenemos control sobre el
        contenido y las políticas de esos sitios, y no podemos ser responsables
        de sus respectivas prácticas de privacidad.
      </p>
      <h3>Cambios en esta política</h3>
      <p>
        A nuestra discreción, podremos cambiar nuestra política de privacidad
        para reflejar actualizaciones en nuestros procesos de negocio, prácticas
        aceptables actuales, o cambios legislativos o regulatorios. Si decidimos
        modificar esta política de privacidad, publicaremos los cambios aquí en
        el mismo enlace en el cual usted esta accediendo a esta política de
        privacidad.
      </p>
      <p>
        Si es requerido por ley, le pediremos su permiso o le daremos la
        oportunidad de aceptar o no, según sea aplicable, cualquier nuevo uso
        que le demos a su información personal.
      </p>
      <h3>Contáctenos</h3>
      <p>
        Ante cualquier pregunta o inquietud respecto a su privacidad, puede
        contactarnos a:
      </p>
      <p>
        Badge Sash
        <br />
        info@badge-sash.com
      </p>
    </>
  ),
  en: (
    <>
      <p>
        Your privacy is important to us. It is Badge Sash&#39;s policy to
        respect your privacy and comply with any applicable law and regulation
        regarding any personal information we may collect about you, including
        across our website,{" "}
        <a href="https://badge-sash.com">https://badge-sash.com</a>, and other
        sites we own and operate.
      </p>
      <p>
        This policy is effective as of 5 April 2022 and was last updated on 5
        April 2022.
      </p>
      <h3>Information We Collect</h3>
      <p>
        Information we collect includes both information you knowingly and
        actively provide us when using or participating in any of our services
        and promotions, and any information automatically sent by your devices
        in the course of accessing our products and services.
      </p>
      <h4>Log Data</h4>
      <p>
        When you visit our website, our servers may automatically log the
        standard data provided by your web browser. It may include your device’s
        Internet Protocol (IP) address, your browser type and version, the pages
        you visit, the time and date of your visit, the time spent on each page,
        other details about your visit, and technical details that occur in
        conjunction with any errors you may encounter.
      </p>
      <p>
        Please be aware that while this information may not be personally
        identifying by itself, it may be possible to combine it with other data
        to personally identify individual persons.
      </p>
      <h4>Personal Information</h4>
      <p>
        We may ask for personal information which may include one or more of the
        following:
      </p>
      <ul>
        <li>Name</li>
        <li>Email</li>
        <li>Phone/mobile number</li>
        <li>Digital badges</li>
      </ul>
      <h4>Legitimate Reasons for Processing Your Personal Information</h4>
      <p>
        We only collect and use your personal information when we have a
        legitimate reason for doing so. In which instance, we only collect
        personal information that is reasonably necessary to provide our
        services to you.
      </p>
      <h4>Collection and Use of Information</h4>
      <p>
        We may collect personal information from you when you do any of the
        following on our website:
      </p>
      <ul>
        <li>
          Sign up to receive updates from us via email or social media channels
        </li>
        <li>Use a mobile device or web browser to access our content</li>
        <li>
          Contact us via email, social media, or on any similar technologies
        </li>
        <li>When you mention us on social media</li>
      </ul>
      <p>
        We may collect, hold, use, and disclose information for the following
        purposes, and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </p>
      <p>
        We may collect, hold, use, and disclose information for the following
        purposes, and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </p>
      <ul>
        <li>to contact and communicate with you</li>
        <li>
          to enable you to access and use our website, associated applications,
          and associated social media platforms
        </li>
        <li>for internal record keeping and administrative purposes</li>
      </ul>
      <p>
        Please be aware that we may combine information we collect about you
        with general information or research data we receive from other trusted
        sources.
      </p>
      <h4>Security of Your Personal Information</h4>
      <p>
        When we collect and process personal information, and while we retain
        this information, we will protect it within commercially acceptable
        means to prevent loss and theft, as well as unauthorized access,
        disclosure, copying, use, or modification.
      </p>
      <p>
        Although we will do our best to protect the personal information you
        provide to us, we advise that no method of electronic transmission or
        storage is 100% secure, and no one can guarantee absolute data security.
        We will comply with laws applicable to us in respect of any data breach.
      </p>
      <p>
        You are responsible for selecting any password and its overall security
        strength, ensuring the security of your own information within the
        bounds of our services.
      </p>
      <h4>How Long We Keep Your Personal Information</h4>
      <p>
        We keep your personal information only for as long as we need to. This
        time period may depend on what we are using your information for, in
        accordance with this privacy policy. If your personal information is no
        longer required, we will delete it or make it anonymous by removing all
        details that identify you.
      </p>
      <p>
        However, if necessary, we may retain your personal information for our
        compliance with a legal, accounting, or reporting obligation or for
        archiving purposes in the public interest, scientific, or historical
        research purposes or statistical purposes.
      </p>
      <h3>Children’s Privacy</h3>
      <p>
        We do not aim any of our products or services directly at children under
        the age of 13, and we do not knowingly collect personal information
        about children under 13.
      </p>
      <h3>Disclosure of Personal Information to Third Parties</h3>
      <p>We may disclose personal information to: </p>
      <ul>
        <li>a parent, subsidiary, or affiliate of our company</li>
        <li>
          third party service providers for the purpose of enabling them to
          provide their services, for example, IT service providers, data
          storage, hosting and server providers, advertisers, or analytics
          platforms
        </li>
        <li>our employees, contractors, and/or related entities</li>
        <li>our existing or potential agents or business partners</li>
        <li>
          sponsors or promoters of any competition, sweepstakes, or promotion we
          run
        </li>
        <li>
          courts, tribunals, regulatory authorities, and law enforcement
          officers, as required by law, in connection with any actual or
          prospective legal proceedings, or in order to establish, exercise, or
          defend our legal rights
        </li>
        <li>
          third parties, including agents or sub-contractors, who assist us in
          providing information, products, services, or direct marketing to you
          third parties to collect and process data
        </li>
      </ul>
      <h3>International Transfers of Personal Information</h3>
      <p>
        The personal information we collect is stored and/or processed where we
        or our partners, affiliates, and third-party providers maintain
        facilities. Please be aware that the locations to which we store,
        process, or transfer your personal information may not have the same
        data protection laws as the country in which you initially provided the
        information. If we transfer your personal information to third parties
        in other countries: (i) we will perform those transfers in accordance
        with the requirements of applicable law; and (ii) we will protect the
        transferred personal information in accordance with this privacy policy.
      </p>
      <h3>Your Rights and Controlling Your Personal Information</h3>
      <p>
        You always retain the right to withhold personal information from us,
        with the understanding that your experience of our website may be
        affected. We will not discriminate against you for exercising any of
        your rights over your personal information. If you do provide us with
        personal information you understand that we will collect, hold, use and
        disclose it in accordance with this privacy policy. You retain the right
        to request details of any personal information we hold about you.
      </p>
      <p>
        If we receive personal information about you from a third party, we will
        protect it as set out in this privacy policy. If you are a third party
        providing personal information about somebody else, you represent and
        warrant that you have such person’s consent to provide the personal
        information to us.
      </p>
      <p>
        If you have previously agreed to us using your personal information for
        direct marketing purposes, you may change your mind at any time. We will
        provide you with the ability to unsubscribe from our email-database or
        opt out of communications. Please be aware we may need to request
        specific information from you to help us confirm your identity.
      </p>
      <p>
        If you believe that any information we hold about you is inaccurate, out
        of date, incomplete, irrelevant, or misleading, please contact us using
        the details provided in this privacy policy. We will take reasonable
        steps to correct any information found to be inaccurate, incomplete,
        misleading, or out of date.
      </p>
      <p>
        If you believe that we have breached a relevant data protection law and
        wish to make a complaint, please contact us using the details below and
        provide us with full details of the alleged breach. We will promptly
        investigate your complaint and respond to you, in writing, setting out
        the outcome of our investigation and the steps we will take to deal with
        your complaint. You also have the right to contact a regulatory body or
        data protection authority in relation to your complaint.
      </p>
      <h3>Use of Cookies</h3>
      <p>
        We use &ldquo;cookies&rdquo; to collect information about you and your
        activity across our site. A cookie is a small piece of data that our
        website stores on your computer, and accesses each time you visit, so we
        can understand how you use our site. This helps us serve you content
        based on preferences you have specified.
      </p>
      <h3>Limits of Our Policy</h3>
      <p>
        Our website may link to external sites that are not operated by us.
        Please be aware that we have no control over the content and policies of
        those sites, and cannot accept responsibility or liability for their
        respective privacy practices.
      </p>
      <h3>Changes to This Policy</h3>
      <p>
        At our discretion, we may change our privacy policy to reflect updates
        to our business processes, current acceptable practices, or legislative
        or regulatory changes. If we decide to change this privacy policy, we
        will post the changes here at the same link by which you are accessing
        this privacy policy.
      </p>
      <p>
        If required by law, we will get your permission or give you the
        opportunity to opt in to or opt out of, as applicable, any new uses of
        your personal information.
      </p>
      <h3>Contact Us</h3>
      <p>
        For any questions or concerns regarding your privacy, you may contact us
        using the following details:
      </p>
      <p>
        Badge Sash
        <br />
        info@badge-sash.com
      </p>
    </>
  ),
};

export { privacyPolicy };
