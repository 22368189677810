import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { routes } from "../../config/routes";
import { UserContext } from "../../context/UserProvider";

const DesktopMenu = () => {
  const { user, loadingUser } = useContext(UserContext);

  const menuRoutes = user ? routes.protected : routes.public;

  if (loadingUser) {
    return null;
  }

  return (
    <>
      {menuRoutes.map((route) => (
        <Button component={Link} to={route.to} key={route.to}>
          {route.message}
        </Button>
      ))}
    </>
  );
};

export default DesktopMenu;
