import API from "../utils/API";
import Service from "./Service";
import format from "date-fns/format";

class ShareLinksService extends Service {
  queries = {
    getShareLinkByHash: (...params) =>
      `query { byHashQueryShareLink(
        hash:"${params[0]}"
      ) { url, hash, badge { rawJson, parsedGraph } } }`,
    getShareLinksByShareableObject: (...params) =>
      `query { byShareableObjectQueryShareLinks(
        shareableObject:"${params[0]}"
      ) { url, hash, note, hasExpiration, expiration } }`,
    createShareLink: (...params) => `mutation { createShareLink (
        input:{shareableObject:"${params[0]}"}
      ) { shareLink { url, hash, note, expiration, hasExpiration } } }`,
    modifyShareLink: (...params) => `mutation { modifyShareLink (
        input:{ shareLink:"${params[0]}" 
        ${params[1] ? `, note:"${params[1]}"` : ``} 
        ${params[2] ? `, expiration:"${params[2]}"` : ``}}
      ) { shareLink { url, hash, note, expiration, hasExpiration } } }`,
    removeShareLink: (...params) => `mutation { removeShareLink (
        input:{ shareLink:"${params[0]}" }
      ) { shareLink { hash } } }`,
  };

  addShareLink = (iri) => {
    return API.callGraphql(this.queries.createShareLink(iri)).then(
      (response) => {
        this.errorParser(response);

        if (
          !response.data ||
          !response.data.createShareLink ||
          !response.data.createShareLink.shareLink
        )
          this.throwDefaultError();

        if (response.data.createShareLink.shareLink.expiration) {
          response.data.createShareLink.shareLink.expiration = new Date(
            response.data.createShareLink.shareLink.expiration
          );
        }

        return response.data.createShareLink.shareLink;
      }
    );
  };

  editShareLink = (shareLink) => {
    let expiration = shareLink.hasExpiration
      ? format(shareLink.expiration, "yyyy-MM-dd'T'HH:mm:ssxxx")
      : null;
    return API.callGraphql(
      this.queries.modifyShareLink(
        "/api/share_links/" + shareLink.hash,
        shareLink.note,
        expiration
      )
    ).then((response) => {
      this.errorParser(response);

      if (
        !response.data ||
        !response.data.modifyShareLink ||
        !response.data.modifyShareLink.shareLink
      )
        this.throwDefaultError();

      if (response.data.modifyShareLink.shareLink.expiration) {
        response.data.modifyShareLink.shareLink.expiration = new Date(
          response.data.modifyShareLink.shareLink.expiration
        );
      }

      return response.data.modifyShareLink.shareLink;
    });
  };

  removeShareLink = (shareLink) => {
    return API.callGraphql(
      this.queries.removeShareLink("/api/share_links/" + shareLink.hash)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.removeShareLink)
        this.throwDefaultError();

      return response.data.removeShareLink.shareLink;
    });
  };

  getShareLinks = (iri) => {
    return API.callGraphql(
      this.queries.getShareLinksByShareableObject(iri)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.byShareableObjectQueryShareLinks)
        this.throwDefaultError();

      response.data.byShareableObjectQueryShareLinks.map((shareLink) => {
        if (shareLink.expiration)
          shareLink.expiration = new Date(shareLink.expiration);
        return shareLink;
      });

      return response.data.byShareableObjectQueryShareLinks;
    });
  };

  getShareLink = (hash) => {
    return API.callGraphql(this.queries.getShareLinkByHash(hash)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data || !response.data.byHashQueryShareLink)
          this.throwDefaultError();

        if (response.data.byHashQueryShareLink.badge)
          response.data.byHashQueryShareLink.badge =
            (response.data.byHashQueryShareLink.badge.parsedGraph &&
              JSON.parse(
                response.data.byHashQueryShareLink.badge.parsedGraph
              )) ||
            (response.data.byHashQueryShareLink.badge.rawJson &&
              JSON.parse(response.data.byHashQueryShareLink.badge.rawJson));

        return response.data.byHashQueryShareLink;
      }
    );
  };
}

export default new ShareLinksService();
