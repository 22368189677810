import React, { useContext } from "react";
import MuiLink from "@material-ui/core/Link/Link";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { UserContext } from "../../context/UserProvider";
import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AuthService from "../../services/AuthService";
import { Skeleton } from "@material-ui/lab";
import AvatarMenu from "../Common/AvatarMenu";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    display: "flex",
    flexFlow: "row",
  },
  skeletonElement: {
    flexGrow: 1,
  },
}));

const UserMenu = () => {
  const classes = useStyles();
  const { user, setUser, loadingUser } = useContext(UserContext);

  const handleLogout = () => {
    setUser(false);
    AuthService.logout();
  };

  if (loadingUser) {
    return (
      <div className={classes.skeleton}>
        <Hidden mdDown>
          <Skeleton
            animation="wave"
            variant="rect"
            width={150}
            className={classes.skeletonElement}
          />
        </Hidden>
        <Skeleton
          animation="wave"
          variant="circle"
          width={40}
          height={40}
          className={classes.skeletonElement}
        />
      </div>
    );
  }

  if (user) {
    return (
      <>
        <Box display="flex" alignItems="center" mr={1}>
          <Hidden mdDown>
            <Box mr={1}>
              <Typography color="textSecondary" variant="body2">
                {user.email}
              </Typography>
            </Box>
          </Hidden>
          <AvatarMenu avatarAlt={user.email[0]} avatarImage={user.avatarImage}>
            <Box justifyItems="center" py={1} px={4}>
              <h3>{user.email}</h3>
            </Box>
            <Divider></Divider>
            <Box
              display="flex"
              justifyContent="space-between"
              py={1}
              px={1}
              flexDirection="row"
            >
              <Button
                component={Link}
                variant="outlined"
                to="/profile"
                data-testid="buttonProfile"
                endIcon={<Icon fontSize="small">edit</Icon>}
              >
                <FormattedMessage
                  id="routes.protected.profile"
                  defaultMessage="Profile"
                  description="Profile menu link"
                />
              </Button>
              <Button
                onClick={handleLogout}
                variant="outlined"
                data-testid="buttonLogout"
                endIcon={<Icon fontSize="small">logout</Icon>}
              >
                <FormattedMessage
                  id="header.signout"
                  description="Sign Out button"
                  defaultMessage="Sign Out"
                />
              </Button>
            </Box>
            <Divider></Divider>
            <Box
              display="flex"
              justifyContent="space-between"
              py={1}
              px={1}
              flexDirection="row"
            >
              <Box mx={1}>
                <MuiLink
                  to="/terms"
                  target="_blank"
                  color="secondary"
                  component={Link}
                >
                  <Typography
                    style={{ textTransform: "uppercase", fontSize: "0.7em" }}
                  >
                    <FormattedMessage
                      id="form.terms"
                      description="Terms and conditions lnk"
                      defaultMessage="terms and conditions"
                    />
                  </Typography>
                </MuiLink>
              </Box>
              <Box mx={1}>
                <Typography
                  style={{ textTransform: "uppercase", fontSize: "0.7em" }}
                >
                  <MuiLink
                    to="/privacy-policy"
                    target="_blank"
                    color="secondary"
                    component={Link}
                  >
                    <FormattedMessage
                      id="form.privacy-policy"
                      description="Pivacy policy link"
                      defaultMessage="privacy policy"
                    />
                  </MuiLink>
                </Typography>
              </Box>
            </Box>
          </AvatarMenu>
        </Box>
      </>
    );
  } else {
    return (
      <Box display="grid" alignItems="middle" justifyItems="flex-end" mr={1}>
        <Hidden mdUp>
          <AvatarMenu>
            <Box
              display="flex"
              justifyContent="space-between"
              py={1}
              px={1}
              flexDirection="row"
            >
              <Button component={Link} to="/login">
                <FormattedMessage
                  id="header.login"
                  description="Log In button"
                  defaultMessage="Log In"
                />
              </Button>
              <Button
                component={Link}
                to="/signup"
                color="primary"
                variant="contained"
              >
                <FormattedMessage
                  id="header.signup"
                  description="Sign Up button"
                  defaultMessage="Sign Up"
                />
              </Button>
            </Box>
          </AvatarMenu>
        </Hidden>
        <Hidden smDown>
          <Grid container spacing={1}>
            <Grid item>
              <Button component={Link} to="/login">
                <FormattedMessage
                  id="header.login"
                  description="Log In button"
                  defaultMessage="Log In"
                />
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                to="/signup"
                color="primary"
                variant="contained"
              >
                <FormattedMessage
                  id="header.signup"
                  description="Sign Up button"
                  defaultMessage="Sign Up"
                />
              </Button>
            </Grid>
          </Grid>
        </Hidden>
      </Box>
    );
  }
};

export default UserMenu;
