import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { IdentitiesContext } from "../../context/IdentitiesProvider";
import IdentityItemIcon from "./IdentityItemIcon";
import IdentityItemOptionsMenu from "./IdentityItemOptionsMenu";
import IdentityItemStatus from "./IdentityItemStatus";

const IdentityItem = (props) => {
  const [identity, setIdentity] = useState(props.identity);
  const { currentIdentity } = useContext(IdentitiesContext);

  useEffect(() => {
    if (
      identity &&
      currentIdentity &&
      currentIdentity.identityValue === identity.identityValue
    ) {
      setIdentity(currentIdentity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIdentity]);

  if (!identity) {
    return <></>;
  }

  return (
    <Paper variant="outlined" data-testid="identity">
      <Grid
        container
        alignContent="center"
        spacing={1}
        style={{ minHeight: "4em" }}
      >
        <Grid item container xs={8} alignContent="center">
          <Box px={1}>
            <IdentityItemIcon
              type={identity.type}
              fontSize="small"
              color="action"
            />
          </Box>
          <Box px={1}>
            <Typography variant="body2">{identity.identityValue}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3} alignContent="center" container>
          <IdentityItemStatus
            status={identity.status}
            iconProps={{ fontSize: "small" }}
          />
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IdentityItemOptionsMenu identity={identity} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IdentityItem;
