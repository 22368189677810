import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddIdentityDialog from "../components/Identities/AddIdentityDialog";
import ValidateIdentityDialog from "../components/Identities/ValidateIdentityDialog";
import usePromise from "../hooks/usePromise";
import ProfileService from "../services/ProfileService";
import ValidationResultDialog from "../components/Identities/ValidationResultDialog";
import { useIntl } from "react-intl";

const IdentitiesContext = React.createContext();

const IdentitiesProvider = (props) => {
  const intl = useIntl();
  const params = useParams();
  const [data, errors, loading, setPromise, reset] = usePromise();
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [validationDialogOpen, setValidationDialogOpen] = useState(false);
  const [validationResultDialogOpen, setValidationResultDialogOpen] = useState(
    false
  );
  const [currentIdentity, setCurrentIdentity] = useState(false);

  useEffect(() => {
    if (params.identityValidationHash) {
      setPromise(
        ProfileService.setErrorMessages({
          "The code is wrong.": intl.formatMessage({
            id: "errors.identity-validation-wrong-code",
            defaultMessage: "Invalid validation code.",
          }),
          "The Identity is already validated.": intl.formatMessage({
            id: "errors.identity-validation-already-validated",
            defaultMessage: "This Identity has been already validated.",
          }),
        }).validateIdentity(params.identityValidationHash)
      );
      setValidationResultDialogOpen(true);
    }
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.identityValidationHash]);

  const handleRegisterButtonClick = () => {
    setCurrentIdentity(false);
    setRegisterDialogOpen(true);
  };

  const handleRegisterDialogClose = (data) => {
    data && data.identity && setCurrentIdentity(data.identity);
    setRegisterDialogOpen(false);
  };

  const handleValidateButtonClick = (identity) => {
    setCurrentIdentity(identity);
    setValidationDialogOpen(true);
  };

  const handleValidationDialogClose = (data) => {
    data &&
      data.identity &&
      setCurrentIdentity({ ...currentIdentity, status: data.identity.status });
    setValidationDialogOpen(false);
  };

  const handleValidationResultDialogClose = () => {
    setValidationResultDialogOpen(false);
  };

  return (
    <IdentitiesContext.Provider
      value={{
        handleRegisterButtonClick,
        handleValidateButtonClick,
        currentIdentity,
      }}
    >
      {loading ? (
        <Box display="grid" justifyItems="center">
          <CircularProgress />
        </Box>
      ) : (
        props.children
      )}
      <AddIdentityDialog
        open={registerDialogOpen}
        handleClose={handleRegisterDialogClose}
      />
      <ValidateIdentityDialog
        open={validationDialogOpen}
        handleClose={handleValidationDialogClose}
        identity={currentIdentity}
      />
      <ValidationResultDialog
        open={validationResultDialogOpen}
        handleClose={handleValidationResultDialogClose}
        identity={data.identity ?? false}
        errors={errors}
        loading={loading}
      />
    </IdentitiesContext.Provider>
  );
};

export { IdentitiesProvider, IdentitiesContext };
