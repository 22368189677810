import { Box, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { privacyPolicy } from "../config/privacyPolicy";
import { LanguageContext } from "../context/LanguageProvider";
import DefaultLayout from "../layouts/DefaultLayout";

const PrivacyPolicy = () => {
  const { locale } = useContext(LanguageContext);

  const translatedPrivacyPolicy = privacyPolicy[locale];

  return (
    <DefaultLayout>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="privacy-policy.title"
            description="Privacy Policy title"
            defaultMessage="Privacy Policy"
          />
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="body2">{translatedPrivacyPolicy}</Typography>
      </Box>
    </DefaultLayout>
  );
};

export default PrivacyPolicy;
