import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import { BadgesValidator } from "matedapps-badges-validator";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout";
import API from "../utils/API";

const Validator = () => {
  return (
    <DefaultLayout>
      <Box my={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.validator"
              defaultMessage="Validator"
              description="Validator menu link"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <BadgesValidator
        validationApiURL={process.env.REACT_APP_VALIDATOR_URL}
        axios={API.axios()}
      />
    </DefaultLayout>
  );
};

export default Validator;
