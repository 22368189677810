import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { ShareLinksContext } from "../../context/ShareLinksProvider";
import { LanguageContext } from "../../context/LanguageProvider";
import LoadingButton from "../Common/LoadingButton";
import ShareLinksService from "../../services/ShareLinksService";
import usePromise from "../../hooks/usePromise";
import { FormattedMessage, useIntl } from "react-intl";
import useSnackbar from "../../hooks/useSnackbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dateFnsLocales from "../../config/dateFnsLocales";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { isPast } from "date-fns";

const ShareLink = (props) => {
  const intl = useIntl();
  const { handleShareLinkRemoveButtonClick } = useContext(ShareLinksContext);
  const [shareLink, setShareLink] = useState(props.shareLink);
  const [expanded, setExpanded] = useState(props.shareLink.added);
  const [mustSave, setMustSave] = useState(false);
  const [data, errors, loading, setPromise, reset] = usePromise();
  const [showSnackbar] = useSnackbar();
  const { locale } = useContext(LanguageContext);

  const handleEditButtonClick = () => {
    setExpanded(true);
  };

  const handleCloseButtonClick = () => {
    setShareLink(props.shareLink);
    setExpanded(false);
    setMustSave(false);
  };

  const handleInputChange = (event) => {
    if (event.target.name === "hasExpiration") {
      setShareLink({
        ...shareLink,
        [event.target.name]: !shareLink.hasExpiration,
      });
    } else {
      setShareLink({
        ...shareLink,
        [event.target.name]: event.target.value,
      });
    }

    setMustSave(true);
  };

  const handleExpirationDateChange = (date) => {
    setShareLink({
      ...shareLink,
      expiration: date,
    });

    setMustSave(true);
  };

  const handleShareLinkSaveButtonClick = () => {
    setPromise(ShareLinksService.editShareLink(shareLink));
  };

  useEffect(() => {
    if (errors && errors.length) {
      showSnackbar(errors.shift(), "error");
    }
    if (data && !errors) {
      setShareLink(data);
      setExpanded(false);
    }
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, errors]);

  return (
    <Paper variant="outlined">
      <LocalizationProvider
        dateAdapter={DateFnsAdapter}
        locale={dateFnsLocales[locale]}
      >
        <Box p={expanded ? 2 : 1}>
          {!expanded ? (
            <Box display="flex">
              <Box display="grid" flexGrow={1} alignContent="center">
                <Typography
                  variant="caption"
                  color={
                    shareLink.expiration && isPast(shareLink.expiration)
                      ? "error"
                      : "textSecondary"
                  }
                >
                  {shareLink.expiration
                    ? isPast(shareLink.expiration)
                      ? intl.formatMessage({
                          id: "share-link.expired-text",
                          defaultMessage: "Expired",
                        })
                      : intl.formatMessage(
                          {
                            id: "share-link.expiration-text",
                            defaultMessage: "Expires in {expiration}",
                          },
                          {
                            expiration: formatDistanceToNow(
                              shareLink.expiration,
                              { locale: dateFnsLocales[locale] }
                            ),
                          }
                        )
                    : intl.formatMessage({
                        id: "share-link.no-expiration-text",
                        defaultMessage: "No expiration",
                      })}
                </Typography>
                <Typography variant="body1">{shareLink.url}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {shareLink.note}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexShrink={1}
                alignContent="center"
                flexDirection="row"
              >
                {!shareLink.expiration || !isPast(shareLink.expiration) ? (
                  <CopyToClipboard text={shareLink.url}>
                    <Button>
                      <Icon>file_copy</Icon>
                    </Button>
                  </CopyToClipboard>
                ) : (
                  <></>
                )}
                <Button
                  onClick={handleEditButtonClick}
                  data-testid="editShareLinkButton"
                >
                  <Icon>edit</Icon>
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box>
                <TextField
                  label={intl.formatMessage({
                    id: "share-link-form.url-label",
                    defaultMessage: "URL",
                  })}
                  type="text"
                  value={shareLink.url}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard text={shareLink.url}>
                          <IconButton
                            aria-label={intl.formatMessage({
                              id: "share-link-form.copy-share-link",
                              defaultMessage: "Copy share link",
                            })}
                          >
                            <Icon>file_copy</Icon>
                          </IconButton>
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  label={intl.formatMessage({
                    id: "share-link-form.note-label",
                    defaultMessage: "Note",
                  })}
                  type="text"
                  name="note"
                  value={shareLink.note}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  helperText={intl.formatMessage({
                    id: "share-link-form.note-helper-text",
                    defaultMessage:
                      "Only to help you. Won't be visible for anyone.",
                  })}
                  FormHelperTextProps={{
                    variant: "standard",
                  }}
                  inputProps={{ "data-testid": "noteInput" }}
                />
              </Box>
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shareLink.hasExpiration}
                      onChange={handleInputChange}
                      name="hasExpiration"
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({
                    id: "share-link-form.has-expiration-label",
                    defaultMessage: "Has expiration",
                  })}
                />
                {shareLink.hasExpiration && (
                  <MobileDatePicker
                    label={intl.formatMessage({
                      id: "share-link-form.expiration-date-label",
                      defaultMessage: "Expiration Date",
                    })}
                    renderInput={(props) => (
                      <TextField variant="outlined" {...props} />
                    )}
                    value={shareLink.expiration}
                    onChange={(date) => handleExpirationDateChange(date)}
                    minDate={Date.now()}
                  />
                )}
              </Box>
              <Box mt={2} display="flex">
                <Box flexGrow={1}>
                  <Button
                    onClick={() => handleShareLinkRemoveButtonClick(shareLink)}
                    variant="outlined"
                  >
                    <FormattedMessage
                      id="button.delete"
                      defaultMessage="Delete"
                    />
                  </Button>
                </Box>
                <Box flexShrink={1} display="flex" justifyItems="">
                  <Button onClick={handleCloseButtonClick} mr={1}>
                    <FormattedMessage
                      id="button.close"
                      defaultMessage="Close"
                    />
                  </Button>
                  <LoadingButton
                    ml={1}
                    loading={loading ?? null}
                    onClick={() => handleShareLinkSaveButtonClick(shareLink)}
                    variant="contained"
                    color="primary"
                    disabled={!mustSave}
                  >
                    <FormattedMessage id="button.save" defaultMessage="Save" />
                  </LoadingButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </LocalizationProvider>
    </Paper>
  );
};

export default ShareLink;
