import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import usePromise from "../../hooks/usePromise";
import BadgesService from "../../services/BadgesService";
import FileUploadService from "../../services/FileUploadService";
import ErrorsAlert from "../Common/ErrorsAlert";

const useStyles = makeStyles((theme) => ({
  badgeId: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    padding: "0.3em",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    "& > *": {
      fontSize: "0.9em",
    },
  },
}));

const AddBadgesResultItem = (props) => {
  const classes = useStyles();
  const [badge, setBadge] = useState(props.badge);
  const [data, errors, loading, setPromise] = usePromise();

  useEffect(() => {
    if (!props.badge) {
      return;
    }
    if (props.badge.badgeText) {
      setPromise(
        BadgesService.addBadge(props.badge.badgeText, props.badge.file)
      );
    }
    if (props.badge.file) {
      setPromise(
        FileUploadService.uploadFile(props.badge.file).then((uploadedFile) =>
          BadgesService.addBadge(props.badge.badgeText, uploadedFile["@id"])
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.badge]);

  useEffect(() => {
    if (data || errors) {
      props.onProcessFinish && props.onProcessFinish(badge);
      data && data.badge && setBadge(data.badge);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, errors]);

  if (!props.badge) {
    return <></>;
  }

  return (
    <Paper>
      {!badge.uuid && (
        <Box className={classes.badgeId}>
          {badge.badgeText ? (
            <Typography noWrap>
              <FormattedMessage
                id="badge-id.text"
                defaultMessage="Text:"
                description="Badge text id label"
              />{" "}
              {badge.badgeText}
            </Typography>
          ) : (
            <Typography noWrap>
              <FormattedMessage
                id="badge-id.file"
                defaultMessage="File:"
                description="Badge File id label"
              />{" "}
              {badge.file.path}
            </Typography>
          )}
        </Box>
      )}
      {loading && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress size="1.5rem" />
          <Typography display="inline" ml={2}>
            <FormattedMessage
              id="status.processing"
              defaultMessage="Processing..."
              description="Status processing"
            />
          </Typography>
        </Box>
      )}
      {errors && (
        <Box p={2}>
          <ErrorsAlert errors={errors} />
        </Box>
      )}
      {badge.uuid && (
        <Grid container spacing={2}>
          <Grid item md={2}>
            <Box p={1} display="grid" justifyItems="center" alignItems="center">
              {badge.designImage ? (
                <img src={badge.designImage} alt={badge.title} />
              ) : (
                <Icon>image</Icon>
              )}
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box p={1}>
              <Typography>{badge.title}</Typography>
              <Typography
                color={
                  badge.identity && badge.identity.status !== "validated"
                    ? "error"
                    : "textSecondary"
                }
              >
                {badge.identity && badge.identity.identityValue}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box
              p={1}
              display="grid"
              justifyItems="right"
              alignContent="center"
            >
              <Typography
                color={badge.revoked ? "error" : "secondary"}
                display="block"
              >
                {badge.revoked ? (
                  <FormattedMessage
                    id="badge-status.revoked"
                    defaultMessage="Revoked"
                    description="Badge revoked status"
                  />
                ) : (
                  <FormattedMessage
                    id="badge-status.valid"
                    defaultMessage="Valid"
                    description="Badge valid status"
                  />
                )}
              </Typography>
            </Box>
          </Grid>
          {badge.identity && badge.identity.status !== "validated" && (
            <Grid item xs>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="error">warning</Icon>
                <Typography color="error" display="block">
                  <FormattedMessage
                    nowrap
                    id="badges.not-issued-for-identity-message"
                    defaultMessage="This badge is not issued to one of your identities."
                    description="Message shown in a badge when it isn't issued to one of the user identities"
                  />
                </Typography>
                <Button
                  color="primary"
                  to="/profile#identities"
                  component={Link}
                >
                  <FormattedMessage
                    nowrap
                    id="button.validate-identity"
                    defaultMessage="Validate Identity"
                    description="Validate Identity button"
                  />
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
};

export default AddBadgesResultItem;
