import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import ErrorsAlert from "../Common/ErrorsAlert";
import BadgesListGridItem from "./BadgesListGridItem";

const BadgesListGrid = (props) => {
  if (props.loading) {
    return (
      <Box display="grid" justifyItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (props.errors && props.errors.length) {
    return (
      <Box display="grid" justifyItems="center">
        <ErrorsAlert errors={props.errors} />
      </Box>
    );
  }

  if (!props.badges) {
    return <></>;
  }

  if (!props.badges.length) {
    return (
      <Box display="grid" justifyContent="center" my={2}>
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage
            id="grid.nothing-found"
            defaultMessage="Nothing found."
            description="Text shown when grid is empty."
          />
        </Typography>
      </Box>
    );
  }

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        {props.badges.map((badge) => (
          <Grid item key={badge.uuid} xs={6} sm={4} md={3} lg={2}>
            <BadgesListGridItem key={badge.uuid} badge={badge} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BadgesListGrid;
