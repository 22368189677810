import { Avatar } from "@material-ui/core";
import React from "react";

const UserAvatar = (props) => {
  const { alt, image, ...rest } = props;

  return (
    <Avatar alt={alt ?? null} src={image} {...rest}>
      {alt ? alt[0] : null}
    </Avatar>
  );
};

export default UserAvatar;
