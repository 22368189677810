import {
  Box,
  Button,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const BadgesListOrderFilter = (props) => {
  const intl = useIntl();

  const orderFilterOptions = {
    title: intl.formatMessage({
      id: "order-filter.title-option",
      defaultMessage: "Title",
      description: "Title option in order filter",
    }),
    issuedDate: intl.formatMessage({
      id: "order-filter.issued-date-option",
      defaultMessage: "Issued Date",
      description: "Title option in order filter",
    }),
    addedDate: intl.formatMessage({
      id: "order-filter.added-date-option",
      defaultMessage: "Added Date",
      description: "Title option in order filter",
    }),
  };

  return (
    <Box display="flex">
      <FormControl size="small" fullWidth>
        <InputLabel variant="outlined">
          <FormattedMessage
            id="badges-filters.sort-by-label"
            defaultMessage="Sort By"
            description="Sort By filter label."
          />
        </InputLabel>
        <Select
          label={
            <FormattedMessage
              id="badges-filters.sort-by-label"
              defaultMessage="Sort By"
              description="Sort By filter label."
            />
          }
          value={props.sortBy ?? "title"}
          onChange={(evt) => props.handleSortByChange(evt.target.value)}
          variant="outlined"
          inputProps={{ "data-testid": "changeSortBy" }}
        >
          <MenuItem value="title">{orderFilterOptions.title}</MenuItem>
          <MenuItem value="addedDate">{orderFilterOptions.addedDate}</MenuItem>
          <MenuItem value="issuedDate">
            {orderFilterOptions.issuedDate}
          </MenuItem>
        </Select>
      </FormControl>
      <Button onClick={props.handleOrderChange} data-testid="changeOrderButton">
        <Icon>
          {props.order === "DESC" ? "arrow_downward_icon" : "arrow_upward_icon"}
        </Icon>
      </Button>
    </Box>
  );
};

export default BadgesListOrderFilter;
