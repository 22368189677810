import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "./context/ThemeProvider";
import { LanguageProvider } from "./context/LanguageProvider";
import { UserProvider } from "./context/UserProvider";
import { SnackbarProvider } from "./context/SnackbarProvider";
import { Switch, Route, Router } from "react-router-dom";
import ProtectedRoute from "./components/Misc/ProtectedRoute";
import history from "./utils/history";
import * as Pages from "./pages";
import ShareLinkRouter from "./components/ShareLinks/ShareLinkRouter";
import { ShareLinksProvider } from "./context/ShareLinksProvider";

function App() {
  return (
    <UserProvider>
      <LanguageProvider>
        <ThemeProvider>
          <SnackbarProvider>
            <ShareLinksProvider>
              <>
                <CssBaseline />
                <Router history={history}>
                  <Switch>
                    <Route path="/terms" component={Pages.Terms} />
                    <Route
                      path="/privacy-policy"
                      component={Pages.PrivacyPolicy}
                    />
                    <Route path="/login" component={Pages.Login} />
                    <Route path="/signup" component={Pages.SignUp} />
                    <Route
                      path="/reset-password/:resetHash"
                      component={Pages.ResetPassword}
                    />
                    <Route
                      path="/reset-password"
                      component={Pages.ResetPassword}
                    />
                    <Route
                      path="/validate/:validationHash"
                      component={Pages.SignUp}
                    />
                    <Route
                      path="/share/:shareLinkHash"
                      component={ShareLinkRouter}
                    />
                    <ProtectedRoute
                      path="/badge/:hash"
                      component={Pages.Badge}
                    />
                    <ProtectedRoute
                      path="/badges/:pageNumber"
                      component={Pages.Badges}
                    />
                    <ProtectedRoute path="/badges" component={Pages.Badges} />
                    <ProtectedRoute
                      path="/add-badges"
                      component={Pages.AddBadges}
                    />
                    <ProtectedRoute
                      path="/groups/:pageNumber"
                      component={Pages.Groups}
                    />
                    <ProtectedRoute path="/groups" component={Pages.Groups} />
                    <ProtectedRoute
                      path="/profile/:identityValidationHash"
                      component={Pages.Profile}
                    />
                    <ProtectedRoute path="/profile" component={Pages.Profile} />
                    <ProtectedRoute
                      path="/validator"
                      component={Pages.Validator}
                    />
                    <ProtectedRoute path="/" component={Pages.Badges} />
                  </Switch>
                </Router>
              </>
            </ShareLinksProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LanguageProvider>
    </UserProvider>
  );
}

export default App;
