import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ShareLinksList from "./ShareLinksList";

const ShareLinksDialog = (props) => {
  const intl = useIntl();

  const dialogTitle =
    props.dialogTitle ??
    intl.formatMessage({
      id: "share-links-dialog.default-title",
      defaultMessage: "Share Links",
    });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="share-links-dialog"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="share-links-dialog">{dialogTitle}</DialogTitle>
      <DialogContent dividers={true}>
        <ShareLinksList />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          <FormattedMessage id="button.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareLinksDialog;
