import React from "react";
import { Redirect, Route } from "react-router-dom";
import API from "../../utils/API";

const ProtectedRoute = ({ children, ...rest }) => {
  return (
    <Route {...rest}>
      {API.hasAccessToken() ? (
        children
      ) : (
        <Redirect to={rest.loginPath || "/login"} />
      )}
    </Route>
  );
};

export default ProtectedRoute;
