import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import AddBadgesResultItem from "./AddBadgesResultItem";

const AddBadgesResult = (props) => {
  const [processing, setProcessing] = useState(true);
  const [processedBadges, setProcessedBadges] = useState([]);

  const onProcessFinish = (processedBadge) => {
    setProcessedBadges((processedBadges) => [
      ...processedBadges,
      processedBadge,
    ]);
  };

  useEffect(() => {
    if (!props.badges) {
      return;
    }
    if (processedBadges.length === props.badges.length) {
      setProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedBadges]);

  if (!props.badges) {
    return <></>;
  }

  return (
    <Box>
      {props.badges.map((badge) => (
        <Box mt={2} key={badge.key}>
          <AddBadgesResultItem
            badge={badge}
            onProcessFinish={onProcessFinish}
          />
        </Box>
      ))}
      {!processing && (
        <>
          <Box mt={4} display="grid" justifyItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={props.handleResetFormClick}
              data-testid="resetButton"
            >
              <FormattedMessage
                nowrap
                id="add-badges.add-more-badges-button"
                defaultMessage="Add more badges"
                description="Add more badges button"
              />
            </Button>
          </Box>
          <Box mt={1} display="grid" justifyItems="center">
            <Button to="/badges" component={Link}>
              <FormattedMessage
                nowrap
                id="add-badges.go-to-badges-button"
                defaultMessage="Go to badges list"
                description="Go to badges list button"
              />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddBadgesResult;
