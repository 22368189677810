import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import IdentitiesList from "../components/Identities/IdentitiesList";
import ProfileForm from "../components/Profile/ProfileForm";
import { IdentitiesProvider } from "../context/IdentitiesProvider";
import usePromise from "../hooks/usePromise";
import DefaultLayout from "../layouts/DefaultLayout";
import ProfileService from "../services/ProfileService";

const Profile = () => {
  const intl = useIntl();
  const [profileData, errorsLoading, loading, setLoadPromise] = usePromise();
  const [data, errors, saving, setSavePromise] = usePromise();

  const handleProfileFormSubmit = (formData) => {
    return setSavePromise(ProfileService.editProfile(formData));
  };

  useEffect(() => {
    setLoadPromise(
      ProfileService.setErrorMessages({
        Error: intl.formatMessage({
          id: "errors.unexpected-error",
          defaultMessage: "An unexpected error occurred.",
        }),
      }).getProfile()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <Box my={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.profile"
              defaultMessage="Profile"
              description="Profile menu link"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="routes.protected.profile"
            defaultMessage="Profile"
            description="Profile menu link"
          />
        </Typography>
      </Box>

      <Box my={2}>
        {loading && (
          <Box display="grid" justifyItems="center" my={4}>
            <CircularProgress />
          </Box>
        )}
        {errorsLoading && (
          <Box display="grid" justifyItems="center" my={4}>
            <ErrorsAlert errors={errorsLoading} />
          </Box>
        )}
        {!loading && profileData && (
          <ProfileForm
            profile={profileData}
            loading={saving && !data}
            errors={errors}
            handleFormSubmit={handleProfileFormSubmit}
          />
        )}
      </Box>
      <Box my={5}>
        <Divider />
      </Box>
      <Box my={5}>
        <IdentitiesProvider>
          <IdentitiesList />
        </IdentitiesProvider>
      </Box>
    </DefaultLayout>
  );
};

export default Profile;
