import API from "../utils/API";
import Service from "./Service";

class BadgesService extends Service {
  queries = {
    getBadges: (...params) =>
      `query{currentUserQueryBadges(
        page:${parseInt(params[0]) ?? 1}, 
        itemsPerPage:${parseInt(params[1]) ?? 10}
        ${params[2] ? 'search:"' + params[2] + '"' : ""}
        ${params[3] ? 'sortBy:"' + params[3] + '"' : ""}
        ${params[4] ? 'order:"' + params[4] + '"' : ""}
      ) { 
        collection { 
        uuid, title, description, issuedOn, revoked, designImage, hash, hasJws, identity { type, identityValue, status } 
      }, 
        paginationInfo { 
          totalCount, lastPage 
        } } }`,
    addBadge: (
      ...params
    ) => `mutation{addMutationBadge(input:{data:"${params[0]}",uploadedfile:"${params[1]}"}){
          badge{ id, uuid, title, description, issuedOn, revoked, designImage, identity { type, identityValue, status }}
        }}`,
    getBadgeByHash: (...params) =>
      `query{byHashQueryBadge(hash:"${params[0]}"){ uuid, rawJson, parsedGraph, identity { status } }}`,
  };

  getBadges = (page, itemsPerPage, search, sortBy, order) => {
    return API.callGraphql(
      this.queries.getBadges(page, itemsPerPage, search, sortBy, order)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.currentUserQueryBadges)
        this.throwDefaultError();

      return response.data.currentUserQueryBadges;
    });
  };

  addBadge = (badgeText, fileIRI) => {
    return API.callGraphql(this.queries.addBadge(badgeText, fileIRI)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data || !response.data.addMutationBadge)
          this.throwDefaultError();

        return response.data.addMutationBadge;
      }
    );
  };

  getBadgeByHash = (hash) => {
    return API.callGraphql(this.queries.getBadgeByHash(hash)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data || !response.data.byHashQueryBadge)
          this.throwDefaultError();

        let badge = response.data.byHashQueryBadge;

        badge.json =
          (badge.parsedGraph && JSON.parse(badge.parsedGraph)) ||
          (badge.rawJson && JSON.parse(badge.rawJson));

        return badge;
      }
    );
  };
}

export default new BadgesService();
