import { Box, Container, makeStyles } from "@material-ui/core";
import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
  },
}));

const DefaultLayout = (props) => {
  const classes = useStyles();

  return (
    <Box overflow="auto" height="100vh" display="flex" flexDirection="column">
      <Box flexShrink={1}>
        <Header />
      </Box>
      <Box flexGrow={1}>
        <Container>{props.children}</Container>
      </Box>
      <Box flexShrink={1}>
        <Container maxWidth={false} disableGutters={true}>
          <Footer className={classes.footer} />
        </Container>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
