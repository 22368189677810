import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Icon,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import MoreOptionsMenu from "../Common/MoreOptionsMenu";
import BadgesListGridItemStatus from "./BadgesListGridItemStatus";
import defaultBadgeImage from "../../default.svg";
import { Link } from "react-router-dom";
import { ShareLinksContext } from "../../context/ShareLinksProvider";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "10px",
  },
  content: {
    padding: "14px",
    "&:last-child": {
      paddingBottom: "16px",
    },
    "& > .MuiTypography-body1": {
      height: "1.5em",
    },
    "& > .MuiTypography-body2": {
      height: "3em",
    },
  },
  media: {
    height: "100px",
    backgroundSize: "contain",
  },
  moreOptionsButton: {
    padding: theme.spacing(2),
  },
}));

const BadgesListGridItem = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { handleShareButtonClick } = useContext(ShareLinksContext);

  if (!props.badge) {
    return null;
  }

  const badgeWithJwsTooltip = intl.formatMessage({
    id: "badge.badge-with-jws-message",
    defaultMessage: "This badge was signed with a valid key.",
  });

  return (
    <Box>
      <Card>
        <CardHeader
          avatar={
            <>
              <BadgesListGridItemStatus status={props.badge.identity.status} />
              {props.badge.hasJws && (
                <Tooltip title={badgeWithJwsTooltip} aria-label="pending">
                  <Icon fontSize="small" color="primary">
                    verified_user
                  </Icon>
                </Tooltip>
              )}
            </>
          }
          action={
            <MoreOptionsMenu placement="bottom-end">
              <Box display="block">
                <Button
                  component={Link}
                  to={"/badge/" + props.badge.hash}
                  startIcon={<Icon>pageview</Icon>}
                  fullWidth
                  className={classes.moreOptionsButton}
                >
                  <FormattedMessage
                    id="button.open-badge"
                    defaultMessage="Open Badge"
                  />
                </Button>
              </Box>
              {props.badge.identity.status === "validated" && (
                <>
                  <Divider></Divider>
                  <Box display="block">
                    <Button
                      onClick={() =>
                        handleShareButtonClick(
                          "/api/badges/" + props.badge.uuid
                        )
                      }
                      display="block"
                      fullWidth
                      startIcon={<Icon>share</Icon>}
                      className={classes.moreOptionsButton}
                    >
                      <FormattedMessage
                        id="button.share"
                        defaultMessage="Share"
                      />
                    </Button>
                  </Box>
                </>
              )}
            </MoreOptionsMenu>
          }
          className={classes.header}
        />
        <CardActionArea component={Link} to={`/badge/${props.badge.hash}`}>
          <CardMedia
            image={props.badge.designImage ?? defaultBadgeImage}
            title={props.badge.title}
            className={classes.media}
          />
        </CardActionArea>
        <CardContent className={classes.content}>
          <Typography variant="body1" component="p" noWrap>
            {props.badge.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-box",
              "-webkit-line-clamp": 2,
              "-webkit-box-orient": "vertical",
            }}
          >
            {props.badge.description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BadgesListGridItem;
