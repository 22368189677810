import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import BadgesList from "../components/Badges/BadgesList";
import DefaultLayout from "../layouts/DefaultLayout";

const Badges = () => {
  return (
    <DefaultLayout>
      <Box my={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.badges"
              defaultMessage="Badges"
              description="Badges menu link and breadcrumbs title"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="routes.protected.badges"
            defaultMessage="Badges"
            description="Badges menu link and breadcrumbs title"
          />
        </Typography>
      </Box>

      <BadgesList />
    </DefaultLayout>
  );
};

export default Badges;
