import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { FormattedMessage, useIntl } from "react-intl";
import TextValidator from "react-material-ui-form-validator/lib/TextValidator";
import LoadingButton from "../Common/LoadingButton";

const LogInForm = (props) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({ email: "", password: "" });

  const handleInputChange = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
  };

  const inputs = {
    email: {
      label: intl.formatMessage({
        id: "form.email-input",
        defaultMessage: "Email",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.email-placeholder",
        defaultMessage: "Please enter your email",
        description: "Email input placeholder",
      }),
    },
    password: {
      label: intl.formatMessage({
        id: "form.password-input",
        defaultMessage: "Password",
        description: "Password input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.password-placeholder",
        defaultMessage: "Please enter your password",
        description: "Password input placeholder",
      }),
    },
  };

  return (
    <ValidatorForm
      onSubmit={() => props.handleFormSubmit(formData)}
      data-testid="formLogin"
    >
      <Box>
        <TextValidator
          onChange={handleInputChange}
          name="email"
          label={inputs.email.label}
          value={formData["email"]}
          placeholder={inputs.email.placeholder}
          fullWidth
          validators={["required", "isEmail"]}
          errorMessages={[
            validationMessages.required,
            validationMessages.isEmail,
          ]}
          inputProps={{ "data-testid": "inputEmail" }}
        />
      </Box>
      <Box mt={1}>
        <TextValidator
          onChange={handleInputChange}
          name="password"
          type="password"
          value={formData["password"]}
          label={inputs.password.label}
          placeholder={inputs.password.placeholder}
          fullWidth
          validators={["required"]}
          errorMessages={[validationMessages.required]}
          inputProps={{ "data-testid": "inputPassword" }}
        />
      </Box>
      <Box mt={2} justifyContent="center" display="flex" alignItems="center">
        <LoadingButton
          loading={props.loading ? "true" : undefined}
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
        >
          <FormattedMessage
            id="form.login-button"
            description="Log In button"
            defaultMessage="Log In"
          />
        </LoadingButton>
      </Box>
    </ValidatorForm>
  );
};

export default LogInForm;
