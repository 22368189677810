import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import AuthService from "../services/AuthService";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import usePromise from "../hooks/usePromise";
import SignUpForm from "../components/Signup/SignUpForm";
import SignUpSuccess from "../components/Signup/SignUpSuccess";
import { Link, useParams } from "react-router-dom";
import SignUpValidation from "../components/Signup/SignUpValidation";
import { FormattedMessage } from "react-intl";
import MuiLink from "@material-ui/core/Link/Link";

const SignUp = () => {
  const params = useParams();
  const [data, errors, loading, setPromise] = usePromise();

  if (params.validationHash) {
    return (
      <DefaultLayout>
        <SignUpValidation />
      </DefaultLayout>
    );
  }

  const handleFormSubmit = (formData) => {
    setPromise(AuthService.register(formData));
  };

  return (
    <DefaultLayout>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="header.signup"
            description="Sign Up button"
            defaultMessage="Sign Up"
          />
        </Typography>
      </Box>
      <Box my={4} display="flex" flexDirection="row" justifyContent="center">
        <Box>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              <ErrorsAlert errors={errors} />
            </Grid>
            <Grid item sm={12}>
              {data.email ? (
                <SignUpSuccess email={data.email} />
              ) : (
                <>
                  <SignUpForm
                    handleFormSubmit={handleFormSubmit}
                    loading={loading}
                  />
                  <Box
                    mt={2}
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                  >
                    <MuiLink to="/login" color="secondary" component={Link}>
                      <FormattedMessage
                        id="form.already-account-link"
                        description="Already have account link"
                        defaultMessage="I already have an account"
                      />
                    </MuiLink>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DefaultLayout>
  );
};

export default SignUp;
