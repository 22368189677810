import React, { useContext, useState } from "react";
import {
  Button,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@material-ui/core";
import { routes } from "../../config/routes";
import { UserContext } from "../../context/UserProvider";
import { Link } from "react-router-dom";

const MobileMenu = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(props.drawerOpen || false);
  const { user } = useContext(UserContext);

  const menuRoutes = user ? routes.protected : routes.public;

  return (
    <>
      <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <Icon>menu</Icon>
      </Button>
      <SwipeableDrawer
        open={drawerOpen}
        onOpen={() => {
          setDrawerOpen(true);
        }}
        onClose={() => {
          setDrawerOpen(false);
        }}
        {...props.disableTransitions}
      >
        <div role="presentation" onClick={() => setDrawerOpen(false)}>
          <List>
            {menuRoutes.map((route) => (
              <ListItem button key={route.to} to={route.to} component={Link}>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.message} />
              </ListItem>
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
