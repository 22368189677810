import {
  Box,
  Button,
  Divider,
  Icon,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";
import LoadingButton from "../Common/LoadingButton";

const useStyle = makeStyles({
  dropzone: {
    borderStyle: "dashed",
  },
});

const AddBadgesForm = (props) => {
  const classes = useStyle();

  const [badgeText, setBadgeText] = useState("");
  const [files, setFiles] = useState([]);

  const handleTextChange = (evt, value, val) => {
    setBadgeText(evt.target.value);
  };

  const handleValidateClick = () => {
    props.handleFormSubmit(badgeText, files);
  };

  const handleFileDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleFileRemove = (fileToRemove) => {
    setFiles(
      files.filter((file) => {
        return fileToRemove.name !== file.name;
      })
    );
  };

  return (
    <>
      <Box mt={2}>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="add-badges.upload-files-title"
            defaultMessage="Upload your Badges."
            description="Title in upload badges by file section."
          />
        </Typography>
        <Box display={files.length ? "none" : ""}>
          <Dropzone
            multiple={true}
            onDrop={(acceptedFiles) => handleFileDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <Box
                display="grid"
                p={2}
                border={1}
                borderRadius={3}
                justifyItems="center"
                className={classes.dropzone}
                {...getRootProps()}
              >
                <input {...getInputProps()} data-testid="dropzone" />
                <Icon color="action">upload</Icon>
                <Typography variant="body1" color="textSecondary">
                  <FormattedMessage
                    id="add-badges.drag-and-drop-text"
                    defaultMessage="Drop your badges here, or click to select files"
                    description="Text shown in drag and drop badges area"
                  />
                </Typography>
              </Box>
            )}
          </Dropzone>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              id="add-badges.drag-and-drop-description"
              defaultMessage="It could be a PNG or SVG image."
              description="Text shown below drag and drop"
            />
          </Typography>
        </Box>
        {files &&
          files.map((file) => (
            <Box key={file.name}>
              {file.name}
              <Button
                onClick={() => handleFileRemove(file)}
                data-testid="removeButton"
              >
                <FormattedMessage
                  id="button.remove"
                  defaultMessage="Remove"
                  description="Remove button"
                />
              </Button>
            </Box>
          ))}
      </Box>
      <Box mt={2}>
        <Divider variant="middle" />
      </Box>
      <Box mt={2} display="grid">
        <Typography variant="subtitle1">
          <FormattedMessage
            id="add-badges.upload-text-title"
            defaultMessage="Add a Badge by URL, JWS or JSON."
            description="Title in upload badges by text section."
          />
        </Typography>
        <TextField
          variant="outlined"
          helperText={
            <FormattedMessage
              id="add-badges.upload-text-description"
              defaultMessage="It could be a URL from a hosted badge, a JWS from a signed badge or just JSON."
              description="Message shown below upload badges by text section."
            />
          }
          placeholder="URL, JWS or JSON"
          value={badgeText}
          onChange={handleTextChange}
          inputProps={{ "data-testid": "inputBadgeText" }}
        ></TextField>
      </Box>
      <Box mt={2} display="grid" justifyContent="center">
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleValidateClick}
          data-testid="submitButton"
        >
          <FormattedMessage
            noWrap={true}
            id="routes.protected.add-badges"
            defaultMessage="Add Badges"
            description="Add Badges link and breadcrumbs title"
          />
        </LoadingButton>
      </Box>
    </>
  );
};

export default AddBadgesForm;
