import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { Box, Hidden, makeStyles, Toolbar } from "@material-ui/core";
import logo from "../../logo.svg";
import UserMenu from "./UserMenu";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

const useStyles = makeStyles((theme) => ({
  appBarLogoBox: {
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
    },
  },
  appBarLogo: {
    height: "6vmin",
  },
}));

function Header() {
  const classes = useStyles();

  return (
    <>
      <AppBar position="relative" color="default">
        <Toolbar disableGutters>
          <Hidden mdUp>
            <Box flexDirection="row">
              <MobileMenu />
            </Box>
          </Hidden>
          <Box
            flexDirection="row"
            textAlign="center"
            className={classes.appBarLogoBox}
            mr={6}
            ml={2}
          >
            <img src={logo} alt="logo" className={classes.appBarLogo} />
          </Box>
          <Hidden smDown>
            <Box flexDirection="row" flexGrow={1}>
              <DesktopMenu />
            </Box>
          </Hidden>
          <Box flexDirection="row">
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
