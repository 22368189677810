import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import usePromise from "../../hooks/usePromise";
import FileUploadService from "../../services/FileUploadService";
import API from "../../utils/API";
import ErrorsAlert from "../Common/ErrorsAlert";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { UserContext } from "../../context/UserProvider";
import ProfileService from "../../services/ProfileService";
import LoadingButton from "../Common/LoadingButton";
import { FormattedMessage, useIntl } from "react-intl";

const ProfileFormAvatarDialog = (props) => {
  const intl = useIntl();
  const { user, setUser } = useContext(UserContext);
  const [preview, setPreview] = useState(false);
  const [crop, setCrop] = useState({});
  const [transformations, setTransformations] = useState({
    scaleX: 1,
    scaleY: 1,
  });
  const [data, errors, loading, setPromise] = usePromise();
  const [
    avatarData,
    errorsSavingAvatar,
    savingAvatar,
    setSaveAvatarPromise,
  ] = usePromise();
  const [
    dataImage,
    errorsLoading,
    loadingImage,
    setPromiseLoadImage,
  ] = usePromise();

  useEffect(() => {
    if (props.file) {
      setPromise(FileUploadService.uploadFile(props.file));
    }
    return () => {
      setPreview(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file]);

  useEffect(() => {
    if (data.uuid) {
      setPromiseLoadImage(API.downloadFile("/uploaded_files/" + data.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (dataImage.data) {
      setPreview(URL.createObjectURL(dataImage.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataImage]);

  useEffect(() => {
    if (avatarData) {
      setUser({ ...user, avatarImage: avatarData.user.avatarImage });
      props.handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarData]);

  const handleReactCropImageLoad = (img) => {
    setTransformations({
      scaleX: img.naturalWidth / img.width,
      scaleY: img.naturalHeight / img.height,
    });

    if (img.naturalWidth > img.naturalHeight) {
      setCrop({
        height: img.height,
        x: (img.width - img.height) / 2,
        unit: "px",
        aspect: 1,
      });
    } else {
      setCrop({
        width: img.width,
        y: (img.height - img.width) / 2,
        unit: "px",
        aspect: 1,
      });
    }

    return false;
  };

  const handleReactCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const handleConfirmClick = () => {
    let finalCrop = {
      x: crop.x * transformations.scaleX,
      y: crop.y * transformations.scaleY,
      width: crop.width * transformations.scaleY,
      height: crop.height * transformations.scaleY,
    };

    return setSaveAvatarPromise(
      ProfileService.setErrorMessages({
        Error: intl.formatMessage({
          id: "errors.unexpected-error",
          defaultMessage: "An unexpected error occurred.",
        }),
      }).editProfileAvatar(data, finalCrop)
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        <FormattedMessage
          id="profile-avatar-dialog.title"
          defaultMessage="Change avatar image"
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          {loading && (
            <Box display="grid" justifyItems="center">
              <CircularProgress />
              <Typography>
                <FormattedMessage
                  id="profile-avatar-dialog.uploading"
                  defaultMessage="Uploading..."
                />
              </Typography>
            </Box>
          )}
          {loadingImage && (
            <Box display="grid" justifyItems="center">
              <CircularProgress />
              <Typography>
                <FormattedMessage
                  id="profile-avatar-dialog.loading"
                  defaultMessage="Loading..."
                />
              </Typography>
            </Box>
          )}
          {errors && <ErrorsAlert errors={errors} />}
          {errorsLoading && <ErrorsAlert errors={errorsLoading} />}
          {errorsSavingAvatar && <ErrorsAlert errors={errorsSavingAvatar} />}
          {preview && (
            <ReactCrop
              circularCrop={true}
              scale={false}
              keepSelection={true}
              src={preview}
              crop={crop}
              onChange={handleReactCropChange}
              onImageLoaded={handleReactCropImageLoad}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <LoadingButton
          loading={savingAvatar ? "true" : null}
          onClick={handleConfirmClick}
          color="primary"
          variant="contained"
          disabled={!dataImage.data}
        >
          <FormattedMessage id="button.confirm" defaultMessage="Confirm" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileFormAvatarDialog;
