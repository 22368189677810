import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import usePromise from "../../hooks/usePromise";
import ShareLinksService from "../../services/ShareLinksService";
import LoadingButton from "../Common/LoadingButton";

const ShareLinkRemoveDialog = (props) => {
  const intl = useIntl();
  const [data, errors, loading, setPromise, reset] = usePromise();

  const dialogTitle =
    props.dialogTitle ??
    intl.formatMessage({
      id: "share-links-remove-dialog.default-title",
      defaultMessage: "Remove share link",
    });

  const handleConfirmRemoveClick = () => {
    setPromise(ShareLinksService.removeShareLink(props.shareLink));
  };

  useEffect(() => {
    if (data && !errors) {
      props.handleConfirm(props.shareLink);
    }
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="share-links-dialog"
    >
      <DialogTitle id="share-links-dialog">{dialogTitle}</DialogTitle>
      <DialogContent dividers={true}>
        <FormattedMessage
          id="share-links-remove-dialog.alert-message"
          defaultMessage="The share link will be removed. Are you sure?"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>
          <FormattedMessage id="button.close" defaultMessage="Close" />
        </Button>
        <LoadingButton
          loading={loading ?? null}
          onClick={handleConfirmRemoveClick}
          variant="contained"
          color="primary"
        >
          <FormattedMessage
            id="button.confirm-delete"
            defaultMessage="Yes, remove"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShareLinkRemoveDialog;
