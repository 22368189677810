import { Box, Button, Icon } from "@material-ui/core";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { IdentitiesContext } from "../../context/IdentitiesProvider";
import MoreOptionsMenu from "../Common/MoreOptionsMenu";

const IdentityItemOptionsMenu = (props) => {
  const intl = useIntl();
  const { handleValidateButtonClick } = useContext(IdentitiesContext);
  const { identity } = props;

  const validateButtons = {
    suggested: {
      icon: "check",
      button: intl.formatMessage({
        id: "button.validate",
        defaultMessage: "Validate",
      }),
    },
    pending: {
      icon: "replay",
      button: intl.formatMessage({
        id: "button.resend-validation",
        defaultMessage: "Resend validation",
      }),
    },
  };

  if (
    !identity ||
    identity.status === "validated" ||
    !validateButtons[identity.status]
  ) {
    return <></>;
  }

  return (
    <MoreOptionsMenu>
      <>
        <Box display="grid" justifyItems="center" py={1} px={2}>
          <Button
            size="small"
            onClick={() => handleValidateButtonClick(identity)}
          >
            <Icon fontSize="small" style={{ marginRight: "1em" }}>
              {validateButtons[identity.status].icon}
            </Icon>
            {validateButtons[identity.status].button}
          </Button>
        </Box>
      </>
    </MoreOptionsMenu>
  );
};

export default IdentityItemOptionsMenu;
