import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Icon,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import usePromise from "../hooks/usePromise";
import DefaultLayout from "../layouts/DefaultLayout";
import BadgesService from "../services/BadgesService";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import BadgeAssertion from "../components/Badges/BadgeAssertion";
import { ShareLinksContext } from "../context/ShareLinksProvider";

const Badge = () => {
  const params = useParams();
  const [data, errors, loading, setPromise, reset] = usePromise();
  const { handleShareButtonClick } = useContext(ShareLinksContext);

  useEffect(() => {
    setPromise(BadgesService.getBadgeByHash(params.hash));
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <Box my={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Link style={{ color: "inherit" }} to="/badges">
            <FormattedMessage
              id="routes.protected.badges"
              defaultMessage="Badges"
              description="Badges menu link and breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.badge"
              defaultMessage="Badge"
              description="Badge menu link and breadcrumbs title"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box my={2}>
        {errors && <ErrorsAlert errors={errors} />}
        {loading && (
          <Box display="grid" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {data && (
          <Box>
            <Box display="grid" justifyContent="right">
              {data.identity.status === "validated" ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleShareButtonClick("/api/badges/" + data.uuid)
                  }
                >
                  <Icon>share</Icon>
                  <FormattedMessage id="button.share" defaultMessage="Share" />
                </Button>
              ) : (
                <></>
              )}
            </Box>
            <BadgeAssertion json={data.json} />
          </Box>
        )}
      </Box>
    </DefaultLayout>
  );
};

export default Badge;
