import { Box, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { terms } from "../config/terms";
import { LanguageContext } from "../context/LanguageProvider";
import DefaultLayout from "../layouts/DefaultLayout";

const Terms = () => {
  const { locale } = useContext(LanguageContext);

  const translatedTerms = terms[locale];

  return (
    <DefaultLayout>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="terms.title"
            description="Terms and Conditions title"
            defaultMessage="Terms and Conditions"
          />
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant="body2">{translatedTerms}</Typography>
      </Box>
    </DefaultLayout>
  );
};

export default Terms;
