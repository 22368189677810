import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import AddBadgesForm from "../components/Badges/AddBadgesForm";
import AddBadgesResult from "../components/Badges/AddBadgesResult";
import DefaultLayout from "../layouts/DefaultLayout";

const AddBadges = () => {
  const [badgesToAdd, setBadgesToAdd] = useState([]);

  const handleFormSubmit = (badgeText, files) => {
    let badges = [];

    if (badgeText) {
      badges.push({ key: "badgeText", badgeText: badgeText, file: "" });
    }

    for (let i in files) {
      badges.push({ key: "file_" + i, file: files[i], badgeText: "" });
    }

    setBadgesToAdd(badges);
  };

  const handleResetFormClick = () => {
    setBadgesToAdd([]);
  };

  return (
    <DefaultLayout>
      <Box my={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Link style={{ color: "inherit" }} to="/badges">
            <FormattedMessage
              id="routes.protected.badges"
              defaultMessage="Badges"
              description="Badges menu link and breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.add-badges"
              defaultMessage="Add Badges"
              description="Add Badges link and breadcrumbs title"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="routes.protected.add-badges"
            defaultMessage="Add Badges"
            description="Add Badges link and breadcrumbs title"
          />
        </Typography>
      </Box>

      {badgesToAdd.length ? (
        <AddBadgesResult
          badges={badgesToAdd}
          handleResetFormClick={handleResetFormClick}
        />
      ) : (
        <AddBadgesForm handleFormSubmit={handleFormSubmit} />
      )}
    </DefaultLayout>
  );
};

export default AddBadges;
