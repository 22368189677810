import { createTheme } from "@material-ui/core";

export const darkTheme = createTheme({
  name: "dark",
  palette: {
    type: "dark",
    primary: {
      main: "#000",
    },
  },
});
