import API from "../utils/API";
import Service from "./Service";

class ProfileService extends Service {
  queries = {
    getProfile: `query{ currentUser{ firstName, lastName, email, phone } }`,
    editProfile: (...params) =>
      `mutation{modifyCurrentUser(input:{
        firstName:"${params[0]}", lastName:"${params[1]}", phone:"${params[2]}"
      }) { 
        user{ firstName, lastName, email, phone }
      }}`,
    editProfileAvatar: (...params) =>
      `mutation{modifyAvatarCurrentUser(input:{
        image:"${params[0]}", x:${params[1]}, y:${params[2]}, w:${params[3]}, h:${params[4]}
      }) { 
        user{ avatarImage }
      }}`,
    registerIdentity: (...params) =>
      `mutation{registerMutationIdentity(input:{type:"${params[0]}", identityValue:"${params[1]}"}){ identity { id, type, identityValue, status }}}`,
    getIdentities: `query{ currentUserQueryIdentities { id, type, identityValue, status }}`,
    validateIdentity: (...params) =>
      `mutation{validateMutationIdentity(input:{code:"${params[0]}"}){ identity { id, type, identityValue, status }}}`,
  };

  editProfile = (formData) => {
    return API.callGraphql(
      this.queries.editProfile(
        formData["firstName"],
        formData["lastName"] ?? "",
        formData["phone"] ?? ""
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.modifyCurrentUser)
        this.throwDefaultError();

      return response.data.modifyCurrentUser;
    });
  };

  getProfile = () => {
    return API.callGraphql(this.queries.getProfile).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.currentUser)
        this.throwDefaultError();

      return response.data.currentUser;
    });
  };

  editProfileAvatar = (fileData, cropData) => {
    return API.callGraphql(
      this.queries.editProfileAvatar(
        fileData["@id"],
        cropData.x,
        cropData.y,
        cropData.width,
        cropData.height
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.modifyAvatarCurrentUser)
        this.throwDefaultError();

      return response.data.modifyAvatarCurrentUser;
    });
  };

  getIdentities = () => {
    return API.callGraphql(this.queries.getIdentities).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.currentUserQueryIdentities)
        this.throwDefaultError();

      return response.data.currentUserQueryIdentities;
    });
  };

  registerIdentity = (identityType, identityValue) => {
    return API.callGraphql(
      this.queries.registerIdentity(identityType, identityValue)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.registerMutationIdentity)
        this.throwDefaultError();

      return response.data.registerMutationIdentity;
    });
  };

  validateIdentity = (identityValidationHash) => {
    return API.callGraphql(
      this.queries.validateIdentity(identityValidationHash)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.validateMutationIdentity)
        this.throwDefaultError();

      return response.data.validateMutationIdentity;
    });
  };
}

export default new ProfileService();
