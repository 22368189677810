import { Box, Grid } from "@material-ui/core";
import React from "react";
import LanguageSwitcher from "../Misc/LanguageSwitcher";
import ThemeSwither from "../Misc/ThemeSwitcher";

const Footer = () => {
  return (
    <Box px={2} py={1}>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <ThemeSwither />
        </Grid>
        <Grid item>
          <LanguageSwitcher />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
