import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import usePromise from "../../hooks/usePromise";
import DefaultLayout from "../../layouts/DefaultLayout";
import ShareLinksService from "../../services/ShareLinksService";
import BadgeAssertion from "../Badges/BadgeAssertion";
import ErrorsAlert from "../Common/ErrorsAlert";

const ShareLinkRouter = () => {
  const { shareLinkHash } = useParams();
  const [shareLink, errors, loading, setPromise, reset] = usePromise();

  useEffect(() => {
    setPromise(ShareLinksService.getShareLink(shareLinkHash));
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareLinkHash]);

  if (loading) {
    return (
      <Box display="grid" justifyContent="center" m={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (errors) {
    return (
      <Box display="grid" justifyContent="center" m={4}>
        <ErrorsAlert errors={errors} />
      </Box>
    );
  }

  if (shareLink.badge) {
    return (
      <DefaultLayout>
        <BadgeAssertion json={shareLink.badge} />
      </DefaultLayout>
    );
  }

  return <></>;
};

export default ShareLinkRouter;
