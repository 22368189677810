import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import ErrorsAlert from "../Common/ErrorsAlert";

const ValidationResultDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="validation-result-dialog"
    >
      <DialogTitle id="max-width-dialog-title">
        <FormattedMessage
          id="validation-dialog.title"
          defaultMessage="Validate Identity"
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        {props.loading && (
          <Box display="grid" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {props.errors && <ErrorsAlert errors={props.errors} />}
        {props.identity && (
          <DialogContentText>
            <FormattedMessage
              id="validation-result-dialog.success-message"
              defaultMessage="Your identity {email} was validated successfully!"
              values={{ email: props.identity.identityValue }}
            />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          <FormattedMessage id="button.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationResultDialog;
