import { Icon, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  label: {
    textTransform: "uppercase",
    fontSize: "1em",
  },
}));

const IdentityItemStatus = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  if (!props.status) {
    return <></>;
  }

  const statuses = {
    validated: {
      icon: "check",
      description: intl.formatMessage({
        id: "identity-status-description.validated",
        defaultMessage: "Validated",
      }),
      tooltip: intl.formatMessage({
        id: "identity-status-tooltip.validated",
        defaultMessage: "This identity was validated successfully",
      }),
      color: "#009900",
    },
    pending: {
      icon: "schedule",
      description: intl.formatMessage({
        id: "identity-status-description.pending",
        defaultMessage: "Pending",
      }),
      tooltip: intl.formatMessage({
        id: "identity-status-tooltip.pending",
        defaultMessage: "This identity is pending of validation.",
      }),
      color: "#CCAA22",
    },
    suggested: {
      icon: "bookmark",
      description: intl.formatMessage({
        id: "identity-status-description.suggested",
        defaultMessage: "Suggested",
      }),
      tooltip: intl.formatMessage({
        id: "identity-status-tooltip.suggested",
        defaultMessage:
          "We detected this identity in one of your badges. Start the validation process by clicking Validate.",
      }),
      color: "#999999",
    },
  };

  const { status, iconProps, ...rest } = props;

  if (!statuses[status]) {
    return <></>;
  }

  return (
    <>
      <Icon
        className={classes.icon}
        style={{ color: statuses[status].color }}
        {...iconProps}
      >
        {statuses[status].icon}
      </Icon>
      <Tooltip
        title={statuses[status].tooltip}
        aria-label={statuses[status].description}
      >
        <Typography
          className={classes.label}
          style={{ color: statuses[status].color }}
          {...rest}
        >
          {statuses[status].description}
        </Typography>
      </Tooltip>
    </>
  );
};

export default IdentityItemStatus;
