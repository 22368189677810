import { Box, Grid, Icon } from "@material-ui/core";
import { ValidatorForm } from "react-form-validator-core";
import React, { useEffect, useState } from "react";
import TextValidator from "react-material-ui-form-validator/lib/TextValidator";
import { FormattedMessage, useIntl } from "react-intl";
import LoadingButton from "../Common/LoadingButton";
import ErrorsAlert from "../Common/ErrorsAlert";
import ProfileFormAvatar from "./ProfileFormAvatar";

const ProfileForm = (props) => {
  const intl = useIntl();
  const defaultProfile = props.profile ?? {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };
  const [mustSave, setMustSave] = useState(false);
  const [formData, setFormData] = useState(defaultProfile);

  useEffect(() => {
    props.profile && setFormData(props.profile);
  }, [props.profile]);

  if (!props.profile) {
    return <></>;
  }

  const handleInputChange = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setMustSave(true);
  };

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
  };

  const inputs = {
    name: {
      label: intl.formatMessage({
        id: "form.name-input",
        defaultMessage: "Name",
        description: "Name input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.name-placeholder",
        defaultMessage: "Please enter your name",
        description: "Name input placeholder",
      }),
    },
    lastname: {
      label: intl.formatMessage({
        id: "form.lastname-input",
        defaultMessage: "Last name (optional)",
        description: "Last name input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.lastname-placeholder",
        defaultMessage: "Please enter your last name",
        description: "Last name input placeholder",
      }),
    },
    phone: {
      label: intl.formatMessage({
        id: "form.phone-input",
        defaultMessage: "Phone (optional)",
        description: "Phone input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.phone-placeholder",
        defaultMessage: "Please enter your phone",
        description: "Phone input placeholder",
      }),
    },
    email: {
      label: intl.formatMessage({
        id: "form.email-input",
        defaultMessage: "Email",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.email-placeholder",
        defaultMessage: "Please enter your email",
        description: "Email input placeholder",
      }),
    },
  };

  return (
    <ValidatorForm
      onSubmit={() => {
        props.handleFormSubmit(formData).then(() => {
          if (!props.errors) setMustSave(false);
        });
      }}
      data-testid="formProfile"
    >
      {props.errors && (
        <Box display="grid" justifyItems="center" my={4}>
          <ErrorsAlert errors={props.errors} />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs sm={12}>
          <Box display="grid" justifyContent="center" mb={4}>
            <ProfileFormAvatar />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            onChange={handleInputChange}
            name="firstName"
            value={formData["firstName"] ?? ""}
            label={inputs.name.label}
            placeholder={inputs.name.placeholder}
            fullWidth
            validators={["required"]}
            errorMessages={[validationMessages.required]}
            inputProps={{ "data-testid": "inputName" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            onChange={handleInputChange}
            name="lastName"
            value={formData["lastName"] ?? ""}
            label={inputs.lastname.label}
            placeholder={inputs.lastname.placeholder}
            fullWidth
            validators={[]}
            errorMessages={[]}
            inputProps={{ "data-testid": "inputLastname" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            onChange={handleInputChange}
            name="phone"
            value={formData["phone"] ?? ""}
            label={inputs.phone.label}
            placeholder={inputs.phone.placeholder}
            fullWidth
            validators={[]}
            errorMessages={[]}
            inputProps={{ "data-testid": "inputPhone" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            onChange={handleInputChange}
            name="email"
            value={formData["email"] ?? ""}
            label={inputs.email.label}
            placeholder={inputs.email.placeholder}
            fullWidth
            validators={["required"]}
            errorMessages={[validationMessages.required]}
            inputProps={{ "data-testid": "inputEmail" }}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs>
          <Box display="grid" justifyContent="right">
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={!mustSave}
              loading={props.loading ? "true" : null}
              type="submit"
            >
              <Icon fontSize="small">save</Icon>
              <FormattedMessage
                id="form.save-button"
                description="Save button"
                defaultMessage="Save"
              />
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default ProfileForm;
