import { Box, Paper, Typography } from "@material-ui/core";
import BadgesValidatorBadgeClass from "matedapps-badges-validator/dist/BadgesValidatorBadgeClass";
import BadgesValidatorEndorsements from "matedapps-badges-validator/dist/BadgesValidatorEndorsements";
import BadgesValidatorEvidence from "matedapps-badges-validator/dist/BadgesValidatorEvidence";
import BadgesValidatorIssuer from "matedapps-badges-validator/dist/BadgesValidatorIssuer";
import BadgesValidatorRecipient from "matedapps-badges-validator/dist/BadgesValidatorRecipient";
import React from "react";
import { FormattedMessage } from "react-intl";

const BadgeAssertion = (props) => {
  if (!props.json) {
    return <></>;
  }

  return (
    <>
      <Box mt={2}>
        <BadgesValidatorBadgeClass badge={props.json.badge} />
      </Box>

      <Box mt={2}>
        <Typography variant="overline">
          <FormattedMessage
            id="badges-validator:recipient-subtitle"
            defaultMessage="Recipient"
            description="Recipient subtitle"
          />
        </Typography>
        <Paper>
          <BadgesValidatorRecipient recipient={props.json.recipient} />
        </Paper>
      </Box>

      <Box mt={2}>
        <Typography variant="overline">
          <FormattedMessage
            id="badges-validator:issued-by-subtitle"
            defaultMessage="Issued by"
            description="Issued by subtitle"
          />
        </Typography>
        <Paper>
          <BadgesValidatorIssuer issuer={props.json.badge.issuer} />
        </Paper>
      </Box>

      {props.json.evidence && (
        <Box mt={2}>
          <Typography variant="overline">
            <FormattedMessage
              id="badges-validator:evidence-subtitle"
              defaultMessage="Evidence"
              description="Evidence subtitle"
            />
          </Typography>
          <Paper>
            <BadgesValidatorEvidence evidence={props.json.evidence} />
          </Paper>
        </Box>
      )}

      {props.json.endorsement && (
        <Box mt={2}>
          <Typography variant="overline">
            <FormattedMessage
              id="badges-validator:endorsements-subtitle"
              defaultMessage="Endorsements"
              description="Endorsements subtitle"
            />
          </Typography>
          <Paper>
            <BadgesValidatorEndorsements endorsement={props.json.endorsement} />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default BadgeAssertion;
