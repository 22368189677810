import {
  ClickAwayListener,
  Icon,
  IconButton,
  Paper,
  Popper,
} from "@material-ui/core";
import React, { useState } from "react";

const MoreOptionsMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreOptionsClick = (evt) => {
    setAnchorEl(anchorEl ? null : evt.currentTarget);
  };

  const handleMoreOptionsClose = (evt) => {
    if (anchorEl.current && anchorEl.current.contains(evt.target)) {
      return;
    }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        onClick={handleMoreOptionsClick}
        data-testid="moreOptionsMenu"
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={props.placement || "left-start"}
      >
        <ClickAwayListener onClickAway={handleMoreOptionsClose}>
          <Paper>{props.children}</Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default MoreOptionsMenu;
