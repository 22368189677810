import { Box, Button, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import history from "../../utils/history";
import usePromise from "../../hooks/usePromise";
import BadgesService from "../../services/BadgesService";
import BadgesListGrid from "./BadgesListGrid";
import { FormattedMessage } from "react-intl";
import BadgesListOrderFilter from "./BadgesListOrderFilter";
import BadgesListSearchFilter from "./BadgesListSearchFilter";

const BadgesList = (props) => {
  const params = useParams();
  const pageNumber = params.pageNumber ? parseInt(params.pageNumber) : 1;
  const limit = props.limit ? parseInt(props.limit, 10) : 12;
  const [sortBy, setSortBy] = useState("addedDate");
  const [order, setOrder] = useState("ASC");
  const [search, setSearch] = useState(null);
  const [data, errors, loading, setPromise] = usePromise();

  useEffect(() => {
    setPromise(
      BadgesService.getBadges(pageNumber, limit, search, sortBy, order)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, search, sortBy, order]);

  const handlePageChange = (event, page) => {
    history.push("/badges/" + page);
  };

  const handleSearchChange = (searchTerm) => {
    setSearch(searchTerm);
  };

  const handleSortByChange = (sort) => {
    setSortBy(sort);
  };

  const handleOrderChange = () => {
    setOrder(order === "ASC" ? "DESC" : "ASC");
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box flexShrink={1} display="flex" flexDirection="row">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <BadgesListSearchFilter handleSearchChange={handleSearchChange} />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <BadgesListOrderFilter
              handleSortByChange={handleSortByChange}
              handleOrderChange={handleOrderChange}
              sortBy={sortBy}
              order={order}
            />
          </Grid>
          <Grid item xs={6} sm={12} md={3} lg={3}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/add-badges"
              fullWidth
            >
              <FormattedMessage
                id="routes.protected.add-badges"
                defaultMessage="Add Badges"
                description="Add Badges link and breadcrumbs title"
              />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} flexGrow={1}>
        <BadgesListGrid
          loading={loading}
          badges={data.collection}
          errors={errors}
        />
      </Box>
      {data.paginationInfo &&
        data.paginationInfo.lastPage > 1 &&
        !loading &&
        !errors && (
          <Box mt={2} flexShrink={1} display="grid" justifyItems="center">
            <Pagination
              count={data.paginationInfo.lastPage}
              page={pageNumber}
              onChange={handlePageChange}
            />
          </Box>
        )}
    </Box>
  );
};

export default BadgesList;
