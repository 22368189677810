import { Icon, Tooltip } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

const BadgesListGridItemStatus = (props) => {
  const intl = useIntl();

  const tooltips = {
    suggested: intl.formatMessage({
      id: "identity-status.suggested",
      description: "Tooltip message in suggested identity icon.",
      defaultMessage: "This badge has been issued to an unvalidated identity.",
    }),
    pending: intl.formatMessage({
      id: "identity-status.pending",
      description: "Tooltip message in pending identity icon.",
      defaultMessage:
        "This badge has been issued to a pending of validation identity.",
    }),
  };

  if (!props.status) {
    return <></>;
  }

  if (props.status === "suggested") {
    return (
      <Tooltip title={tooltips.suggested} aria-label="suggested">
        <Icon fontSize="small" color="error">
          warning
        </Icon>
      </Tooltip>
    );
  }

  if (props.status === "pending") {
    return (
      <Tooltip title={tooltips.pending} aria-label="pending">
        <Icon fontSize="small" color="action">
          schedule
        </Icon>
      </Tooltip>
    );
  }

  return <></>;
};

export default BadgesListGridItemStatus;
