import { createTheme } from "@material-ui/core";

export const lightTheme = createTheme({
  name: "light",
  palette: {
    type: "light",
    primary: {
      light: "#1eaadd",
      main: "#1d7fc3",
      dark: "#2b5572",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#f3661e",
      main: "#cd632d",
      dark: "#9c5c3c",
      contrastText: "#FFF",
    },
  },
});
