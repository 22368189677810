import { Box, Grid, Typography } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link/Link";
import React, { useContext, useEffect } from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import { UserContext } from "../context/UserProvider";
import AuthService from "../services/AuthService";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import history from "../utils/history";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import useSnackbar from "../hooks/useSnackbar";
import LogInForm from "../components/Login/LogInForm";
import usePromise from "../hooks/usePromise";

const Login = () => {
  const intl = useIntl();
  const { setUser } = useContext(UserContext);
  const [showSnackbar] = useSnackbar();
  const [data, errors, loading, setPromise] = usePromise();

  const handleFormSubmit = (formData) => {
    setPromise(
      AuthService.login(formData.email, formData.password, {
        errors: {
          AuthBadCredentials: intl.formatMessage({
            id: "errors.auth-connection-error",
            defaultMessage: "An error ocurred, please try again.",
            description:
              "Message shown in login page when a fatal error occurs.",
          }),
          AuthConnectionError: intl.formatMessage({
            id: "errors.auth-bad-credentials",
            defaultMessage: "Email or password incorrect.",
            description:
              "Message shown in login page when user puts bad credentials.",
          }),
        },
      })
    );
  };

  useEffect(() => {
    if (data.success && data.user) {
      setUser(data.user);
      showSnackbar(
        intl.formatMessage({
          id: "login.success-alert",
          defaultMessage: "Entering the matrix...",
        })
      );

      //TODO referer
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <DefaultLayout>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="header.login"
            description="Log In button"
            defaultMessage="Log In"
          />
        </Typography>
      </Box>
      <Box my={4} display="flex" flexDirection="row" justifyContent="center">
        <Box>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              <ErrorsAlert errors={errors} />
            </Grid>

            <Grid item xs={12}>
              <LogInForm
                handleFormSubmit={handleFormSubmit}
                loading={loading}
              />
              <Box
                mt={2}
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <MuiLink
                  to="/reset-password"
                  color="secondary"
                  component={Link}
                >
                  <FormattedMessage
                    id="form.forgot-password-link"
                    description="Forgot password link"
                    defaultMessage="Forgot password?"
                  />
                </MuiLink>
              </Box>
              <Box
                mt={2}
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <MuiLink to="/signup" color="secondary" component={Link}>
                  <FormattedMessage
                    id="form.create-account-link"
                    description="Create account link"
                    defaultMessage="Create an account"
                  />
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DefaultLayout>
  );
};

export default Login;
