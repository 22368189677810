import { Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const routes = {
  public: [],
  protected: [
    {
      to: "/badges",
      message: (
        <FormattedMessage
          id="routes.protected.badges"
          defaultMessage="Badges"
          description="Badges menu link and breadcrumbs title"
        />
      ),
      icon: <Icon>view_module</Icon>,
    },
    {
      to: "/validator",
      message: (
        <FormattedMessage
          id="routes.protected.validator"
          defaultMessage="Validator"
          description="Validator menu link"
        />
      ),
      icon: <Icon>verified_user</Icon>,
    },
  ],
};

export { routes };
