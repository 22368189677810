import React, { useState, useEffect } from "react";
import AuthService from "../services/AuthService";
import API from "../utils/API";

const UserContext = React.createContext();

const UserProvider = (props) => {
  const [user, setUserState] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    if (!API.hasAccessToken()) {
      return;
    }

    loadUser(false);
  }, []);

  const loadUser = async () => {
    setLoadingUser(true);

    return AuthService.getUser()
      .then((currentUser) => {
        setLoadingUser(false);
        setUserState(currentUser);
      })
      .catch((err) => {
        setLoadingUser(false);
        setUserState(false);
      });
  };

  const setUser = (user) => {
    setUserState(user);
  };

  return (
    <UserContext.Provider value={{ user, setUser, loadingUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
