import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { FormattedMessage, useIntl } from "react-intl";
import TextValidator from "react-material-ui-form-validator/lib/TextValidator";
import usePromise from "../../hooks/usePromise";
import ProfileService from "../../services/ProfileService";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";

const AddIdentityDialog = (props) => {
  const intl = useIntl();
  const [data, errors, loading, setPromise, reset] = usePromise();
  const [email, setEmail] = useState("");
  const addForm = useRef(null);

  const handleFormSubmit = () => {
    setPromise(
      ProfileService.setErrorMessages({
        "The Identity is already validated.": intl.formatMessage({
          id: "errors.add-identity-already-validated",
          defaultMessage: "This identity has been already validated.",
        }),
        "Identity type unknown.": intl.formatMessage({
          id: "errors.unexpected-error",
          defaultMessage: "An unexpected error occurred.",
        }),
      }).registerIdentity("email", email)
    );
  };

  const handleClose = () => {
    props.handleClose(data);
    setEmail("");
    reset();
  };

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
  };

  const inputs = {
    email: {
      label: intl.formatMessage({
        id: "form.email-input",
        defaultMessage: "Email",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.email-placeholder",
        defaultMessage: "Please enter your email",
        description: "Email input placeholder",
      }),
    },
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      data-testid="add-identity-dialog"
    >
      <DialogTitle id="max-width-dialog-title">
        <FormattedMessage
          id="add-identity-dialog.title"
          defaultMessage="Add Identiy"
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        {errors && <ErrorsAlert errors={errors} />}
        <DialogContentText>
          {data ? (
            <FormattedMessage
              id="validation-dialog.success-message"
              defaultMessage="We have sent you an email to {email} for you validation. If you don't see the email, remember to check the spam folder."
              values={{ email: data.identity.identityValue }}
            />
          ) : (
            <ValidatorForm
              onSubmit={handleFormSubmit}
              data-testid="formRegister"
              ref={addForm}
            >
              <FormattedMessage
                id="add-identity-dialog.information-message"
                defaultMessage="We will send you an email for you validation."
              />
              <Box mt={1}>
                <TextValidator
                  onChange={(evt) => setEmail(evt.target.value)}
                  name="email"
                  label={inputs.email.label}
                  value={email}
                  placeholder={inputs.email.placeholder}
                  fullWidth
                  variant="outlined"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    validationMessages.required,
                    validationMessages.isEmail,
                  ]}
                  inputProps={{ "data-testid": "inputEmail" }}
                />
              </Box>
            </ValidatorForm>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {data ? (
          <Button onClick={handleClose} color="primary">
            <FormattedMessage id="button.close" defaultMessage="Close" />
          </Button>
        ) : (
          <>
            <Button onClick={handleClose} color="primary">
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <LoadingButton
              loading={loading ? "true" : null}
              onClick={() => addForm.current.submit()}
              color="primary"
              variant="contained"
              type="submit"
            >
              <FormattedMessage id="button.proceed" defaultMessage="Proceed" />
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddIdentityDialog;
