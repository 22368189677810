import React, { useState } from "react";
import ShareLinkRemoveDialog from "../components/ShareLinks/ShareLinkRemoveDialog";
import ShareLinksDialog from "../components/ShareLinks/ShareLinksDialog";

const ShareLinksContext = React.createContext();

const ShareLinksProvider = (props) => {
  const [shareLinksDialogOpen, setShareLinksDialogOpen] = useState(false);
  const [shareLinkRemoveDialogOpen, setShareLinkRemoveDialogOpen] = useState(
    false
  );
  const [shareLinksOwner, setShareLinksOwner] = useState(null);
  const [currentShareLink, setCurrentShareLink] = useState(null);
  const [removedShareLinksHashes, setRemovedShareLinksHashes] = useState([]);

  const handleShareButtonClick = (owner) => {
    setShareLinksOwner(owner);
    setShareLinksDialogOpen(true);
  };

  const handleShareLinksDialogClose = () => {
    setShareLinksDialogOpen(false);
  };

  const handleShareLinkRemoveButtonClick = (shareLink) => {
    setCurrentShareLink(shareLink);
    setShareLinkRemoveDialogOpen(true);
  };

  const handleShareLinkRemoveDialogClose = () => {
    setShareLinkRemoveDialogOpen(false);
  };

  const handleShareLinkRemoveConfirmButtonClick = (shareLink) => {
    setRemovedShareLinksHashes([...removedShareLinksHashes, shareLink.hash]);
    setShareLinkRemoveDialogOpen(false);
  };

  return (
    <ShareLinksContext.Provider
      value={{
        handleShareButtonClick,
        shareLinksOwner,
        handleShareLinkRemoveButtonClick,
        removedShareLinksHashes,
      }}
    >
      {props.children}
      <ShareLinksDialog
        open={shareLinksDialogOpen}
        handleClose={handleShareLinksDialogClose}
      />
      <ShareLinkRemoveDialog
        shareLink={currentShareLink}
        open={shareLinkRemoveDialogOpen}
        handleClose={handleShareLinkRemoveDialogClose}
        handleConfirm={handleShareLinkRemoveConfirmButtonClick}
      />
    </ShareLinksContext.Provider>
  );
};

export { ShareLinksProvider, ShareLinksContext };
