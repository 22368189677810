import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import usePromise from "../../hooks/usePromise";
import ProfileService from "../../services/ProfileService";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";

const ValidateIdentityDialog = (props) => {
  const intl = useIntl();
  const [data, errors, loading, setPromise, reset] = usePromise();

  if (!props.identity) {
    return <></>;
  }

  const handleConfirmClick = () => {
    setPromise(
      ProfileService.setErrorMessages({
        "The Identity is already validated.": intl.formatMessage({
          id: "errors.add-identity-already-validated",
          defaultMessage: "This identity has been already validated.",
        }),
        "Identity type unknown.": intl.formatMessage({
          id: "errors.unexpected-error",
          defaultMessage: "An unexpected error occurred.",
        }),
      }).registerIdentity(props.identity.type, props.identity.identityValue)
    );
  };

  const handleClose = () => {
    props.handleClose(data);
    setTimeout(() => {
      reset();
    }, 500);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        <FormattedMessage
          id="validation-dialog.title"
          defaultMessage="Validate Identity"
        />
      </DialogTitle>
      <DialogContent dividers={true}>
        {errors && <ErrorsAlert errors={errors} />}
        {data ? (
          <DialogContentText>
            <FormattedMessage
              id="validation-dialog.success-message"
              defaultMessage="We have sent you an email to {email} for you validation. If you don't see the email, remember to check the spam folder."
              values={{ email: props.identity.identityValue }}
            />
          </DialogContentText>
        ) : (
          <DialogContentText>
            <FormattedMessage
              id="validation-dialog.information-message"
              defaultMessage="We will sent you a validation email to {email}."
              values={{ email: props.identity.identityValue }}
            />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {data ? (
          <Button onClick={handleClose} color="primary">
            <FormattedMessage id="button.close" defaultMessage="Close" />
          </Button>
        ) : (
          <>
            <Button onClick={handleClose} color="primary">
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <LoadingButton
              loading={loading ? "true" : null}
              onClick={handleConfirmClick}
              color="primary"
              variant="contained"
            >
              <FormattedMessage id="button.proceed" defaultMessage="Proceed" />
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ValidateIdentityDialog;
