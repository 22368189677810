import { Box, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ShareLinksContext } from "../../context/ShareLinksProvider";
import usePromise from "../../hooks/usePromise";
import useSnackbar from "../../hooks/useSnackbar";
import ShareLinksService from "../../services/ShareLinksService";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";
import ShareLink from "./ShareLink";

const ShareLinksList = () => {
  const [data, errors, loading, setPromise, reset] = usePromise();
  const [addData, errorsAdding, adding, setPromiseAdd, resetAdd] = usePromise();
  const [shareLinks, setShareLinks] = useState([]);
  const { shareLinksOwner, removedShareLinksHashes } = useContext(
    ShareLinksContext
  );
  const [showSnackbar] = useSnackbar();

  useEffect(() => {
    setPromise(ShareLinksService.getShareLinks(shareLinksOwner));
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setShareLinks(data);
    }
    return () => {
      setShareLinks([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (errorsAdding && errorsAdding.length) {
      showSnackbar(errorsAdding.shift(), "error");
    }
    if (addData) {
      setShareLinks([...shareLinks, { ...addData, added: true }]);
    }
    return () => {
      resetAdd();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addData, errorsAdding]);

  useEffect(() => {
    if (removedShareLinksHashes) {
      setShareLinks(
        shareLinks.filter((shareLink) => {
          return removedShareLinksHashes.indexOf(shareLink.hash) === -1;
        })
      );
    }
    return () => {
      setShareLinks(shareLinks);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedShareLinksHashes]);

  const handleAddShareLinkButtonClick = () => {
    setPromiseAdd(ShareLinksService.addShareLink(shareLinksOwner));
  };

  if (loading) {
    return (
      <Box display="grid" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (errors) {
    return <ErrorsAlert errors={errors} />;
  }

  return (
    <>
      {shareLinks.map((shareLink) => (
        <Box mt={1} key={shareLink.hash}>
          <ShareLink shareLink={shareLink} />
        </Box>
      ))}
      <Box mt={2}>
        <LoadingButton
          onClick={handleAddShareLinkButtonClick}
          loading={adding ?? null}
          color="primary"
          variant="outlined"
          fullWidth
        >
          <FormattedMessage
            id="button.add-share-link"
            defaultMessage="Add share link"
          />
        </LoadingButton>
      </Box>
    </>
  );
};

export default ShareLinksList;
